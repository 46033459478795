import React from "react";
import {MenuItem, InputLabel, Select} from "@material-ui/core";

var sassonVesimchaLink = "https://NeedForGeeks.b-cdn.net/Audio/Eli-Zion-Sasson-VSimcha.mp3";
var bereshitOlamLink = "https://NeedForGeeks.b-cdn.net/Audio/Bereshit%20Olam.mp3";
var boiBeshalomLink = "https://NeedForGeeks.b-cdn.net/Audio/Boee%2520Beshalom.mp3";
var samTabaatLink = "https://NeedForGeeks.b-cdn.net/Audio/דודו%20אהרון%20-%20שם%20טבעת%20עליה.mp3";

export default function SongMenuItem(props) {
  return (
    <div className="form-row">
      <div className="form-group col-md">
        <InputLabel style={{ textAlign: "left" }}>Song <span style={{ color: "red" }}>*</span></InputLabel>
        <Select
          value={props.baseSong}
          onChange={props.handleBaseSongChange}
          required type="text" variant="outlined" id="song" style={{ marginTop: 0, width: "100%" }}
        >
          <MenuItem value="" disabled>
            <em>Select Song</em>
          </MenuItem>
          <MenuItem value={sassonVesimchaLink}>Sasson Vesimcha - Eli Zion</MenuItem>
          <MenuItem value={bereshitOlamLink}>Bereshit Olam - Shlomi Shabat</MenuItem>
          <MenuItem value={boiBeshalomLink}>Boei Beshalom - Yaakov Shwekey</MenuItem>
          <MenuItem value={samTabaatLink}>Sam Tabaat Aleiha - Dudu Aharon</MenuItem>
          <MenuItem value={"Custom"}>Custom Song</MenuItem>
        </Select>
      </div>
    </div>
  );
}
