import React from "react";
import Popup from "reactjs-popup";
import Button from 'react-bootstrap/Button'
import InviteSample from "../../../../pages/InviteSample";

 export default class ViewSampleVideoPopUp extends React.Component {
 
    render(){
    return (
    <Popup trigger={ <Button className="btn btn-outline-light" style={{backgroundColor: "#173962", color: "white" }} role="button" >View a Sample</Button>} position="right center">
        <InviteSample />
      </Popup>
    );
    }

}