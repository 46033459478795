// import WeddingForm from "./WeddingForm";
import React from "react";
import AddToCalendar from "react-add-to-calendar";
import "../css/style.css"
// var converter = require('number-to-words');
export default class CalanderButton extends React.Component {
  constructor(props) {
    super(props);
    this.reqObject=props.reqObject;
   
  };
  render() {
    var date = this.reqObject.eventDate;
    var oldDate = new Date(date)
    oldDate.setHours(oldDate.getHours() +3);

   var date2= new Date(date)
   date2.setHours(date2.getHours() +7);
    var eventTitle = this.reqObject.womanName + ' and ' + this.reqObject.manName;
    var pronoun = "their";
    if (this.reqObject.manName === "") {
      eventTitle = this.reqObject.womanName;
      pronoun = "her"
    }

    const formatDate = (dateString) => {
      return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dateString)
     
    }

    let event = {
      title: eventTitle + "'s Occasion",
      description:  eventTitle + ' would love to see you at ' + pronoun + ' occasion',
      location: this.reqObject.venue!=null ? this.reqObject.venue:'TBD',
      startTime: formatDate(oldDate),
      endTime: formatDate(date2)
  };

  let icon = { 'calendar-plus-o': 'left' };
  
    return (
      <div className="btn btn-outline-light" style={{backgroundColor:"#1DAEE0"}}>
        <AddToCalendar
          event={event}
          buttonTemplate={icon}
        />
      </div>
  )}
};

