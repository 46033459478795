import React from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import "../App";

const NotFoundPage = () => {
  window.document.title = "NeedForGeeks | Oops...";
  return (
    <div>
      <div
        id="404"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-1">404: Page Not Found</h1>
          <Typist cursor={{ hideWhenDone: true }} avgTypingDelay={30}>
            <span className="lead">Sorry the page you were looking for no longer exists. Please check out one of our other pages!</span>
          </Typist>
          <Link className="btn btn-outline-light" role="button" to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;