import React from "react";

export default function Pricing() {
    return (
    <div> 
      <div id="pricing" className="jumbotron bg-white jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
        <div className="container container-fluid text-center p-5">
          <h1 className="display-4-overview mb-2 text-center"><b>Invitation Pricing</b></h1>
          <br/>
          <div className="form-row">
            <div className="pricing col-md">
              <p style={{fontSize:"16px"}}><b>Base Fee</b></p>
              <p className="card-text"><strike> $300</strike> <span style={{color:"red"}}> $250</span></p>
              <p className="card-text">Invite as many as you want, for one great price!</p>
            </div>
            <div className="pricing col-md">
              <p style={{fontSize:"16px"}}><b>Custom Design</b></p>
              <p className="card-text"><strike> +$100</strike> <span style={{color:"red"}}> +50</span></p>
              <p className="card-text">Upload your favorite invite and we'll make it for you!</p>
              <p className="text-muted">+ 5-7 Business Days</p>
            </div>
            <div className="pricing col-md">
              <p style={{fontSize:"16px"}}><b>Custom Song</b></p>
              <p className="card-text"><strike> +$30</strike> <span style={{color:"red"}}> FREE</span></p>
              <p className="card-text">Upload your favorite song and we'll play it for you!</p>
              <p className="text-muted">+ 1-2 Business Days</p>
            </div>
            <div className="pricing col-md">
              <p style={{fontSize:"16px"}}><b>Allow RSVP</b></p>
              <p className="card-text">+$50</p>
              <p className="card-text">Allow your invitees to RSVP to your event!</p>
            </div>
          </div>
          <div className="form-row">
            <div className="pricing col-md">
              <p style={{fontSize:"26px"}}><b>Combo Deal</b></p>
              <p style={{fontSize:"20px"}} className="card-text"><strike> $1200</strike> <strike style={{color:"red"}}> $600</strike> <b style={{color:"red"}}> $500</b></p>
              <p style={{fontSize:"20px"}}>Getting Married? Want to go green on all your invites? Create Save the Date, Engagement, Bridal Shower and Wedding Invitations all for the low price of 2 invitations!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
