import React from "react";

export default function RSVPManagement() {
    return (
    <div> 
      <div id="RSVPManagement" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
        <div className="container container-fluid p-5">
          <h1 className="display-4-overview mb-2 text-center">Guest List Management</h1>
          <br/>
          <p className="lead text-center" style={{fontSize: ".9rem"}}>Personal specific functionality makes organizing your online guest list clear and efficient.</p>
          <img alt="..." src={require("./Logos/RSVPImage.png")} />
        </div>
      </div>
    </div>
  );
}
