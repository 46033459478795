import React from "react";
import Typist from "react-typist";

const ChooseTemplateHeader = () => {
  return (
    <div>
      <div
        id="ChooseTemplateHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
        style={{width:"100%",margin:0,padding:0}}
      >
        <div className="container container-fluid text-center mt-5 mb-5">
          <h1 className="display-3">Choose a Template</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }} avgTypingDelay={30}>
            <span className="lead">Choose one of our premade designs, or create your own design! </span>
          </Typist>
        </div>
      </div>
    </div>
  );
};

export default ChooseTemplateHeader;