import React from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import "../App";

const PaymentComplete = () => {
  window.document.title = "NeedForGeeks | Confirmation";
  return (
    <div>
      <div
        id="submit"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-1">Payment Received!</h1>
          <Typist className="lead" avgTypingDelay={30}>We've received your invitation request. A team member will review your submission generally within one business day.</Typist>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentComplete;