import React, { useState } from "react";
import { Chat, Phone } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

const phoneNumber = "3472803596";
const messageTemplate = "Hi, I'm interested in learning more about your services."

const Footer = () => {
  const [bgStyle] = useState({ backgroundColor: "#f5f5f5", marginBottom: 0 });

  return (
    <footer style={bgStyle} className="mt-auto py-3 text-center">

      <div className="row">

        <div className={`col-lg`}>
          <h3 className="display-5  text-center">Quick Links</h3>
          <a style={{ fontSize: "15px" }} role="button" href="https://www.NeedForGeeks.com" target="_blank" rel="noopener noreferrer">Main Site</a>
          <br />
          <Link style={{ fontSize: "15px" }} role="button" to="/How-To-Pay">How To Pay</Link>
          <br />
          <Link style={{ fontSize: "15px" }} role="button" to="/Referrals-Affiliates">Referrals & Affiliates</Link>
          <br />
          <Link style={{ fontSize: "15px" }} role="button" to="/Become-a-Developer">Become a Developer</Link>
          <br />
          <Link style={{ fontSize: "15px" }} role="button" to="/Terms-And-Conditions">Terms And Conditions</Link>

        </div>

        <div className={`col-lg`}>
          <h3 className="display-5  text-center">Contact Us Today</h3>
          <h5>(347) 280-3596</h5>
          <Button style={{ margin: "3px", borderColor: "#1DAEE0", color: "#1DAEE0" }} variant="outlined" size="large" type="submit" startIcon={<Phone />} href={"tel: " + phoneNumber}>Call</Button>
          <Button style={{ margin: "3px", borderColor: "#1DAEE0", color: "#1DAEE0" }} variant="outlined" color="primary" size="large" type="submit" startIcon={<Chat />}
            href={"sms: " + phoneNumber + "&body=" + messageTemplate}>Text</Button>
        </div>

        <div className={`col-lg`}>
          <h3 className="display-5  text-center">We are here for you!</h3>
          <h5 style={{ fontSize: "15px" }} >Serving New York for over 5 years</h5>


        </div>

      </div>

      <br />


      <strong> &copy; 2021 - </strong>
      <i className="fas fa-code"></i> with <i className="fas fa-heart"></i> by{" "}
      <a
        className="badge badge-light"
        rel="noopener"
        href="https://github.com/NeedForGeeksNY"
        aria-label="My GitHub"
      >
        NeedForGeeks
      </a>{" "}
      using <i className="fab fa-react"></i>
      <p>
        <small className="text-muted">
          {" "}
        </small>
      </p>
    </footer>
  );
};

export default Footer;