import React from "react";
import "../assets/css/style.css";
import "./Templates/CSS/Medias.css";
import domtoimage from 'dom-to-image';
import $ from 'jquery';

var downloadName="invitation.jpeg";

class InvitationText extends React.Component {
  constructor(props) {
    super(props);
    console.log("in constructor sidebyside" + props.reqObject.womanName)
    this.sakura = props.isSakura;
    this.requestLine1 = props.reqObject.requestLine1;
    this.womanName = props.reqObject.womanName;
    this.manName = props.reqObject.manName;
    this.day = props.reqObject.day;
    this.date = props.reqObject.date;
    this.year = props.reqObject.year;
    this.time = props.reqObject.time;
    this.cityState = props.reqObject.cityState;
    this.phone = props.reqObject.phone;
    this.footerMessage1 = props.reqObject.footerMessage1;
    this.footerMessage2 = props.reqObject.footerMessage2;
    this.myRef = React.createRef();
    this.changed = props.reqObject.changed;
    this.baseSong = props.reqObject.baseSong;
    this.isSideBySide = props.reqObject.isSideBySide;
    this.reqObject=props.reqObject;
    console.log(props.reqObject.template)
    this.template = props.reqObject.template;


  }

   isupdate = 0;
  componentWillUpdate() {
    console.log("in sakura sidebyside" + this.reqObject.isSideBySide)
    console.log("in sakura sidebyside" + this.props.reqObject.template)
    if (this.reqObject.isSideBySide) {
      this.requestLine1 = this.props.reqObject.requestLine1;
      this.womanName = this.props.reqObject.womanName.capitalize();
      this.manName = this.props.reqObject.manName.capitalize();
      this.day = this.props.reqObject.day;
      this.date = this.props.reqObject.date;
      this.year = this.props.reqObject.year;
      this.time = this.props.reqObject.time;
      this.cityState = this.props.reqObject.cityState;
      this.phone = this.props.reqObject.phone;
      this.footerMessage1 = this.props.reqObject.footerMessage1;
      this.footerMessage2 = this.props.reqObject.footerMessage2;
      this.myRef = React.createRef();
      this.changed = this.props.reqObject.changed;
      this.isSideBySide = this.props.reqObject.isSideBySide;
      this.reqObject =
    {
      isSideBySide:true,
      loadingActive:false,
      customDesign:'',
      template: this.template,
      requestLine1: this.props.reqObject.requestLine1,
      womanName: this.props.reqObject.womanName,
      manName: this.props.reqObject.manName,
      day: this.props.reqObject.day,
      date: this.props.reqObject.date,
      year: this.props.reqObject.year,
      time: this.props.reqObject.time,
      brideInitial : this.props.reqObject.womanName.charAt(0).capitalize(),
      groomInitial :  this.props.reqObject.manName.charAt(0).capitalize(),
      cityState: this.props.reqObject.cityState,
      phone: this.props.reqObject.phone,
      footerMessage1: this.props.reqObject.footerMessage1,
      footerMessage2: this.props.reqObject.footerMessage2,
      changed: this.props.reqObject.changed
    }
      if (this.isupdate === 0) {
        this.isupdate++;
      
        this.setState((prevState, props) => ({
          changed: !this.props.reqObject.changed
        }));
        console.log('supppp' + this.changed)
      }
      else {
        this.isupdate = 0;
      }
    }
  }

  DownloadPdf() {
    if(this.reqObject.isSideBySide)
    {
      window.alert("Downloading Document will be available once payment has been made.")
    }
    else
    {
    window.scrollTo(0, 0)
    document.getElementById("download-button").style.display = "none";
    var node = document.getElementById('fullInvite');
    
    downloadName= this.reqObject.womanName + '_and_' + this.reqObject.manName + `'s_Save_Date.jpeg`;

    domtoimage.toJpeg(node,{ quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = downloadName;
        link.href = dataUrl;
        link.click();
      }).then(function() {
        document.getElementById("download-button").style.display = "block";
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      }).then(function() {
        document.getElementById("download-button").style.display = "block";
      });
    }
  }
  componentDidMount()
  {
    if(window.screen.width > 600)
    {
      this.zoomSquare() 
      window.addEventListener('resize', this.zoomSquare)
    }
  }
  
   zoomSquare() {
    
    var $square = $('#fullCard');

    var viewportHeight = $(window).height();
    var squareHeight = $square.height();
    var desiredHeight = Math.round(viewportHeight * 0.6);
    var zoom = (desiredHeight / squareHeight);

    $square.css('zoom', zoom);
    $square.css('-moz-transform', 'scale(' + zoom + ')');
    $square.css(  '-o-transform', 'scale(' + zoom + ')');
}

  render() {
    var buttonsCss = "venue-save-date " + this.template+"-venue-save-date"
    var buttonDownload = buttonsCss + " downloadButton-save-date"
    var andSignCss = this.template+"-and-sign-save-date";
    var saveDateCss = this.template+"-save-date-text save-date";
    var namesStyle = this.template+"-name-style-save-date name-style-save-date";
    var messageStyle = this.template+"-header-style-save-date message-save-date";
    var spacing = this.template+"-title-save-date spacing-save-date";
    var cityCss = this.template+"-invitation-footer-save-date city-state-save-date";
    var timeVenue = this.template+"-dance-sm-save-date dance-sm-save-date";
    var bottomText = this.template+"-bottom-text-save-date bottom-text-save-date";
    return (
        <div >
         <section id="media" />
          <div className="wrap">
            <div className = {spacing}>
              <p className = {namesStyle}>{this.womanName}&nbsp;<span className={andSignCss}>&amp;&nbsp;</span>{this.manName}</p>
              <p className = {messageStyle}>{this.requestLine1}</p>
              <p className ={saveDateCss}>{this.footerMessage2}</p>
            </div>
          </div>
          <p className={timeVenue}>{this.date}</p>
          <p className={cityCss}>{this.cityState}</p>
          <p className={bottomText}>
            {this.footerMessage1}
            <br/> 
            <span />
          </p>
          <div className="actions">
            <a href={() => false} onClick={() => this.DownloadPdf()} id="download-button">
              <div className={buttonDownload}><b>DOWNLOAD</b></div>
            </a>
          </div>
          <div>
            {this.props.reqObject.isSideBySide ?
              null :
              <audio className="audio-element"  src={this.baseSong}>
              </audio>

            }
          </div>
        </div>
    );
  }
}

export default InvitationText;