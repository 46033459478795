import React from "react";
import { Button } from "@material-ui/core";
import { LibraryMusic, EventAvailableRounded, RoomOutlined,  CardTravel, Payment, EventSeat, GetApp, LinkRounded, Redeem  } from "@material-ui/icons";
import ViewSampleVideoPopUp from "../Invitations/assets/resources/ViewSampleVideoPopUp";

export default function Options() {
  return (
    <div >
      <div id="options" className="  bg-white min-vh-auto " >
        <div className="container container-fluid p-5">
          <div className="row">
            <div className="col-md" style={{ padding: 0,}}>
              <h1 className="display-4-options"><b>Enjoy Matching Online Envelopes and Your Feature-Rich Details Panel</b></h1>
              <p className="display-4  " style={{ fontSize: ".9rem" }}>People will be directed to your optional details panel after viewing your card.</p>
              &nbsp;&nbsp;
              <ViewSampleVideoPopUp />
             
              <div className="row" style={{ marginTop: "10px",}}>
                <div className="col" style={{ padding: 0,}}>
                  <ul className="fa-ul" style={{ margin: 0,}}>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<EventAvailableRounded />}>Calender Links</Button></li>               
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<LibraryMusic />}> Play Music</Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<RoomOutlined />}> Google Maps</Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<CardTravel />}> Venue Information</Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<EventSeat />}> RSVP </Button></li>
                  </ul>
                </div>
                <div className="col" style={{ padding: 0,}}>
                  <ul className="fa-ul" style={{ margin: 0,}}>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<GetApp />}> Download Invitation</Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<LinkRounded />}> Add Links </Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<Redeem />}> Gift Registries </Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" startIcon={<Payment />}> Send Gifts </Button></li>
                    <li><Button disabled style={{ borderColor: "#173962", color: "#173962", textAlign:"left" }} size="small" >  and more... </Button></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md" style={{ marginTop: "10px", padding:0}}>
              <div className="text-center align-self-right">
                <img className=" border-secondary rounded-square" src={require("./Logos/E-Vite.jpg")} alt="..."/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

