import React from "react";
import Classic from "./Templates/Classic";
import Wreath from "./Templates/Wreath";
import Sakura from "./Templates/Sakura";
import GoldDots from "./Templates/GoldDots";
import MarsalaFloral from "./Templates/MarsalaFloral";
import SilverGlitter from "./Templates/SilverGlitter";
import GoldGlitter from "./Templates/GoldGlitter";
import "./Templates/CSS/Medias.css";
import '../assets/css/envelopeCss.scss';
import texturedBackground from "../assets/img/Other/texturedBackground.png";
import silverBackground from "../assets/img/SilverGlitter/background.png";
import CustomInvitation from "./Templates/CustomInvitation";
import EnvelopeLinks from "../assets/resources/EnvelopeLinks";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { doc, getDoc } from "firebase/firestore";

import {db} from '../../../firebase';
const axios = require('axios');
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class Envelope extends React.Component {
  constructor() {
    super();
    this.reqObject =
    {
      eventDate:'',
      registryLinks:'',
      template: '',
      requestLine1: '',
      womanName: '',
      manName: '',
      day: '',
      date: '',
      year: '',
      time: '',
      cityState: '',
      phone: '',
      link: '',
      footerMessage1: '',
      footerMessage2: '',
      changed: false,
      brideInitial:'',
      groomInitial :'',
      customDesign:'',
      isEnvelopeOpen: false
    }
  }
  async componentWillMount() {
    var urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('documentId');
    var there = this;
    var ss = "Invitations";
    if(window.location.href.includes('localhost'))
    {
      ss = 'Test-Invitations';
    }
    const docRef = doc(db, ss, myParam);
    const response =  await getDoc(docRef);
    console.log(response.exists)
        if (response.exists) {
          
        there.template = response.data.template
        if (there.template === "sakura") {
          there.isSakura = true;
        }
        var envelopeColor  = response.data.envelopeColor ==null?"black":response.data.envelopeColor;
        document.documentElement.style.setProperty("--base", envelopeColor);
        document.documentElement.style.setProperty("--base-dark",envelopeColor);
        document.documentElement.style.setProperty("--base-light",envelopeColor);
        document.documentElement.style.setProperty("--letter", envelopeColor);
        if(response.data.template !== 'custom')
        {
          var d = response.data.eventDate.toString();
          var textDate = new Date(d);
          var month = textDate.getMonth() +1;
          var date = month + "." + d.split(" ")[2] + "." + d.split(" ")[3];
        }
      

        // eslint-disable-next-line no-extend-native
        String.prototype.capitalize = function () {
          return this.charAt(0).toUpperCase() + this.slice(1);
        }

        if(response.data.template !== 'custom')
        {
        there.reqObject =
        {
          eventDate : response.data.eventDate.toString(),
          registryLinks:response.data.registryLinks,
          template: response.data.template,
          date: date,
          footerMessage1: response.data.footerMessage1,
          footerMessage2: response.data.footerMessage2,
          customDesign:response.data.customDesign,
          requestLine1: response.data.requestLine1.capitalize(),
          womanName: response.data.brideName.capitalize(),
          manName: response.data.groomName.capitalize(),
          baseSong: response.data.baseSong,
          cityState: response.data.cityState,
          phone: response.data.phone,
          isEnvelopeOpen: false,
          brideInitial:response.data.brideName.charAt(0).capitalize(),
          groomInitial :  response.data.groomName.charAt(0).capitalize(),
        }
      }
      else
      {
        there.reqObject =
        {
          eventDate : response.data.eventDate.toString(),
          registryLinks:response.data.registryLinks,
          brideInitial:response.data.brideName.charAt(0).capitalize(),
          groomInitial :  response.data.groomName.charAt(0).capitalize(),
          womanName: response.data.brideName.capitalize(),
          manName: response.data.groomName.capitalize(),
          customDesign:response.data.customDesign,
          baseSong: response.data.baseSong,
          isEnvelopeOpen: false,
        }
      }
      window.document.title = there.reqObject.manName + " And " + there.reqObject.womanName

        console.log(there.template)
        there.setState({
          changed: true,
          template: there.template
        })
      }
      else
      {
        console.log('no document found')
      }

  }

  closeCard(prop) {
    prop.reqObject.isEnvelopeOpen = false;
    window.closeCard();
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.pause();
    audioEl.currentTime = 0;
  }
  openCard(prop) {
    prop.reqObject.isEnvelopeOpen = true;
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play();
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        audioEl.pause();
        //do whatever you want
        console.log("Hidden");
      }
      else {
        if (prop.reqObject.isEnvelopeOpen)
          audioEl.play();
        //do whatever you want
        console.log("SHOWN");
      }
    });

    window.openCard();
  }
  render() {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (
      this.reqObject.changed === false ? 
      <div className="sweet-loading" style={style}>
      <RingLoader color={'rgb(209, 238, 248)'} loading={true} css={override} size={250} />
      </div>
      :
      <div>
        <EnvelopeLinks reqObject={this.reqObject} />
        <div className="envContainer" >
          <div className="close" onClick={() => this.closeCard(this)}>x</div>
          <div className="content">
            <div id="envelope" className="envelope" onClick={() => this.openCard(this)}>
              <div id="stickerEnvelope" className="my-circle" title="KM">{this.reqObject.brideInitial + '|' + this.reqObject.groomInitial}</div>
            </div>
            <div id="letter" className="letter">
              <div className="body" >
                <div id='message'>
                  <div id="letters" className="EnvelopeDiv">
                    {this.template === 'classic' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid black",
                          // height: "100vh", 
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Classic reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'wreath' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #d9af43",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Wreath reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'marsalaFloral' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #22040F",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <MarsalaFloral reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'silverGlitter' ?
                    <div id='fullInvite'
                      style={{
                        border: "2px solid #B9B9B9",
                        height: "100%",
                        backgroundImage: `url(${silverBackground})`
                      }}>
                      <SilverGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                    </div>
                    :
                    this.template === 'sakura' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #564861",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <Sakura reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'custom' ?
                      <div id='fullInvite' style={{

                        height: "100vh", background: 'transparent'
                      }}>
                        <CustomInvitation reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldDots' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #695746",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldDots reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldGlitter' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #F0B9AD",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="powered">
          <a className="pricing" role="button" href="https://GeekVitations.NeedForGeeks.com" target="_blank" rel="noopener noreferrer" style={{padding: "7px", borderColor:"#173962", color: "#173962"}}>  
            <img src={require('../assets/img/Logos/nav_icon.png')} width="40" height="30" className="d-inline-block align-top" alt=""/>
            <img src={require('../assets/img/Logos/poweredBy.png')} className="d-inline-block align-top" alt=""/>
            <img src={require('../assets/img/Logos/Logo_text.png')} className="d-inline-block align-top align-right" alt=""/>
          </a>
        </h4>
      </div>
    )
  };
}