import WeddingForm from "./Form";
import { Button } from "@material-ui/core";
import { Reply } from "@material-ui/icons";
import React from "react";
import Classic from "./Templates/Classic";
import Wreath from "./Templates/Wreath";
import Sakura from "./Templates/Sakura";
import GoldDots from "./Templates/GoldDots";
import GoldGlitter from "./Templates/GoldGlitter";
import MarsalaFloral from "./Templates/MarsalaFloral";
import SilverGlitter from "./Templates/SilverGlitter";
import CustomInvitation from "./Templates/CustomInvitation";
import FormHeader from "../assets/resources/FormHeader";
import CustomSaveDateForm from "./CustomForm";
import "./Templates/CSS/Medias.css";

class SideBySide extends React.Component {
   
  constructor(props) {
    super();
    // eslint-disable-next-line no-extend-native
    String.prototype.capitalize = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }
    this.template = props.template;
    this.requestLine1 = "are getting married"
    this.womanName = 'Juliet'
    this.manName = 'Romeo'
    this.date = '11.29.2021'
    this.cityState = "Brooklyn, New York"
    this.phone = " 1 (347) 280-3596"
    this.footerMessage1 = `Formal invite to follow`
    this.footerMessage2 = `Save The Date`
    this.changed = false;
    this.brideInitial = this.womanName.charAt(0).capitalize();
    this.groomInitial =  this.manName.charAt(0).capitalize();
    this.reqObject =
    {
      envelopeColor:"black",
      isSideBySide:true,
      loadingActive:false,
      customDesign:'',
      template: this.template,
      requestLine1: this.requestLine1,
      womanName: this.womanName,
      manName: this.manName,
      date: this.date,
      brideInitial : this.womanName.charAt(0).capitalize(),
      groomInitial :  this.manName.charAt(0).capitalize(),
      cityState: this.cityState,
      phone: this.phone,
      footerMessage1: this.footerMessage1,
      footerMessage2: this.footerMessage2,
      changed: this.changed,
      invitationType: "SaveDate"
    }
    this.handleReqObject = this.handleReqObject.bind(this);
  }
  ChooseTemplate() {
    window.location.reload();
  }
  handleReqObject = (value) => {
    // eslint-disable-next-line
    String.prototype.capitalize = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }
    var d = value.eventDate.toString();
    var textDate = new Date(d);
    var month = textDate.getMonth() +1;
    var date = month + "." + d.split(" ")[2] + "." + d.split(" ")[3];
    console.log(value.eventDate)
    this.date = date;
    this.reqObject = {
      template: this.template,
      requestLine1: value.requestLine1,
      womanName: value.womanName,
      manName: value.manName,
      date: date,
      loadingActive: value.loadingActive,
      cityState: value.cityState,
      phone: value.phone,
      footerMessage1: value.footerMessage1,
      footerMessage2: value.footerMessage2,
      changed: value.changed,
      envelopeColor:value.envelopeColor,
      invitationType: "SaveDate"
    }

    this.setState((prevState, props) => ({
      changed: !value.changed,

    }));
    console.log(this.changed)
    console.log(this.reqObject.brideParents + 'ppppp')
  }
 
  render() {
    return (
      <div id="side-by-side-page" style = {{margin: 0,  padding: 0,width:"100%"}} >
         
        <FormHeader />
        <Button variant="contained" onClick={() => this.ChooseTemplate()} style={{ backgroundColor: "#1DAEE0", color: "white", margin:"10px"}} size="large" startIcon={<Reply />}>CHOOSE ANOTHER TEMPLATE</Button>
        <div className="text-center" >
          <div className="form-row">
            <div className="form-group col-md-5">
           { this.template === 'custom' ?
            <CustomSaveDateForm reqObject={this.reqObject} handleReqObject={this.handleReqObject} template={this.template} />
            :<WeddingForm reqObject={this.reqObject} handleReqObject={this.handleReqObject} template={this.template} />
           
            }
            </div>
            {this.template === 'classic' ?
              <div id="letters" className="col-md classic-date-style myDiv">
                <div className="watermark"></div>
                <Classic reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
              :
              this.template === 'goldDots' ?
              <div id="letters" className="col-md goldDots-date-style myDiv">
                <div className="watermark"></div>
                <GoldDots reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
              :
              this.template === 'goldGlitter' ?
              <div id="letters" className="col-md goldGlitter-date-style myDiv">
                <div className="watermark"></div>
                <GoldGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
              :
              this.template === 'silverGlitter' ?
              <div id="letters" className="col-md silverGlitter-date-style myDiv">
                <div className="watermark"></div>
                <SilverGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
              this.template === 'marsalaFloral' ?
              <div id="letters" className="col-md marsalaFloral-date-style myDiv">
                <div className="watermark"></div>
                <MarsalaFloral reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
              </div>
              :
              this.template === 'wreath' ?
                <div className="col-md wreath-date-style myDiv">
                  <div className="watermark"></div>
                  <Wreath reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
                </div>
                :
                this.template === 'custom' ?
                <div className="col-md sakura-date-style myDiv">
                  <div className="watermark"></div>
                  <CustomInvitation reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
                </div>
                 :
                 <div className="col-md sakura-date-style myDiv">
                   <div className="watermark"></div>
                   <Sakura reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
                 </div>
            }
          </div>
        </div>
        
      </div>
    );
  }
}

export default SideBySide;