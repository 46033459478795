import React from "react";
import Typist from "react-typist";

const FormHeader = () => {
  return (
    <div>
      <div
        id="FormHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
        style={{ marginBottom: 0 }}
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Request Form</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }} avgTypingDelay={0} >
            <span className="lead">Parties are always exciting, and we want your loved ones to be there! You can have an online invitation with music playing and animations to send to your loved ones. Hassle free! No more mailing or handing out physical invitations, not knowing whether your loved ones received them or not. Keep up with the virtual and create your personalized link now.</span>
          </Typist>
        </div>
      </div>
    </div>
  );
};

export default FormHeader;