import React, { Fragment, Component } from "react";
import {Route, Switch, Redirect, BrowserRouter as Router} from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import "./components/Invitations/assets/css/aboutStyleCSS.css";
import "./components/Invitations/assets/css/aos.css";
import "./components/Invitations/assets/css/bootstrap.min.css";
import "./components/Invitations/assets/css/experience.css";
import "./components/Invitations/assets/css/main.css";

// Pages
import SubmitPage from "./pages/submit"
import PaymentComplete from "./pages/PaymentComplete"

import ComingSoonPage from "./pages/comingsoon";
import NotFoundPage from "./pages/404";
import InviteSample from "./pages/InviteSample";
import PaymentPage from "./components/Invitations/assets/resources/PaymentPage";

// Main Page
import MainHeader from "./components/Main Page/MainHeader";
import Overview from "./components/Main Page/Overview";
import Options from "./components/Main Page/Options";
import RSVPManagement from "./components/Main Page/RSVPManagement";
import Difference from "./components/Main Page/Difference";
import Customize from "./components/Main Page/TabsCustomizableDesign/Customize";
import Pricing from "./components/Main Page/Pricing";
// import AboutMe from "./components/Main Page/AboutMe";
import ContactForm from "./components/Main Page/ContactMe/contact";

// Wedding Invitations
import Envelope from "./components/Invitations/WeddingFiles/Envelope.jsx";

import ChooseTemplate from "./components/Invitations/WeddingFiles/ChooseTemplate";
import WeddingCustomForm from "./components/Invitations/WeddingFiles/CustomDesignForm";

// Save Date Invitations
import SaveDateEnvelope from "./components/Invitations/SaveDateFiles/Envelope.jsx";
import ChooseSaveDateTemplate from "./components/Invitations/SaveDateFiles/ChooseTemplate";
import SaveDateCustomForm from "./components/Invitations/SaveDateFiles/CustomDesignForm";

// Bridal Shower Invitations
import ShowerEnvelope from "./components/Invitations/ShowerFiles/Envelope.jsx";
import ChooseShowerTemplate from "./components/Invitations/ShowerFiles/ChooseTemplate";
import ShowerCustomForm from "./components/Invitations/ShowerFiles/CustomDesignForm";


// Baby Invitations
import BabyEnvelope from "./components/Invitations/BabyFiles/Envelope.jsx";
import ChooseBabyTemplate from "./components/Invitations/BabyFiles/ChooseTemplate";
import BabyCustomForm from "./components/Invitations/BabyFiles/CustomDesignForm";

// RSVP
import RSVPForm from "./components/Invitations/RSVP/RSVPForm";
import RSVPTable from "./components/Invitations/RSVP/RSVPTable";

// Footer
import Footer from "./components/Footer/Footer";
import Pay from "./components/Footer/HowToPay";
import Developer from "./components/Footer/BecomeADeveloper";
import Terms from "./components/Footer/Terms";
import Stripe from "./components/Invitations/Stripe/Stripe";

// import Test from "./pages/test";

const MainPage = () =>  (
  <Fragment>
    <MainHeader />
    <Overview />
    <Options />
    <RSVPManagement />
    <Difference />
    <Customize />
    <Pricing />
    <ContactForm />
    <Footer />
  </Fragment>
);

const ChooseWeddingTemplatePage = () =>  (
  <Fragment>
    <ChooseTemplate />
    <Footer />
</Fragment>
);

const WeddingInvitationPage = () => (
  <Fragment>
    <Envelope />
  </Fragment>
);
const BabyInvitationPage = () => (
  <Fragment>
    <BabyEnvelope />
  </Fragment>
);

const WeddingCustomFormPage = () =>  (
  <Fragment>
    <WeddingCustomForm />
    <Footer />
  </Fragment>
);

const ChooseSaveDateTemplatePage = () =>  (
  <Fragment>
    <ChooseSaveDateTemplate />
    <Footer />
</Fragment>
);

const SaveDatePage = () =>  (
  <Fragment>
    <SaveDateEnvelope />
</Fragment>
);

const SaveDateCustomFormPage = () =>  (
  <Fragment>
    <SaveDateCustomForm />
    <Footer />
  </Fragment>
);

const ChooseShowerTemplatePage = () =>  (
  <Fragment>
    <ChooseShowerTemplate />
    <Footer />
</Fragment>
);

const ChooseBabyTemplatePage = () =>  (
  <Fragment>
    <ChooseBabyTemplate />
    <Footer />
</Fragment>
);

const ShowerPage = () =>  (
  <Fragment>
    <ShowerEnvelope />
</Fragment>
);
const BabyPage = () =>  (
  <Fragment>
    <BabyEnvelope />
</Fragment>
);
const ShowerCustomFormPage = () =>  (
  <Fragment>
    <ShowerCustomForm />
    <Footer />
  </Fragment>
);
const BabyCustomFormPage = () =>  (
  <Fragment>
    <BabyCustomForm />
    <Footer />
  </Fragment>
);
const StripeFormPage = () =>  (
  <Fragment>
    <Stripe />
    <Footer />
  </Fragment>
);
const InviteSamplePage = () =>  (
  <Fragment>
    <InviteSample />
    <Footer />
</Fragment>
);

const RSVPFormPage = () =>  (
  <Fragment>
    <RSVPForm />
    <Footer />
</Fragment>
);

const RSVPTablePage = () =>  (
  <Fragment>
    <RSVPTable />
    <Footer />
</Fragment>
);

const HowToPay = () =>  (
  <Fragment>
    <Pay />
    <Footer />
</Fragment>
);

const BecomeADeveloper = () =>  (
  <Fragment>
    <Developer />
    <Footer />
  </Fragment>
);

const TermsAndConditions = () =>  (
  <Fragment>
    <Terms />
    <Footer />
  </Fragment>
);

const Payment = () =>  (
  <Fragment>
    <PaymentPage />
    <Footer />
  </Fragment>
);

const Submit = () =>  (
  <Fragment>
    <SubmitPage />
    <Footer />
  </Fragment>
);

const PaymentCompletePage = () =>  (
  <Fragment>
    <PaymentComplete />
    <Footer />
  </Fragment>
);


const ComingSoon = () =>  (
  <Fragment>
    <ComingSoonPage />
    <Footer />
  </Fragment>
);

const NotFound = () =>  (
  <Fragment>
    <NotFoundPage />
    <Footer />
  </Fragment>
);

const TestPage = () =>  (
  <Fragment>
    <Footer />
</Fragment>
);

class App extends Component {
  render() {
    return (
      <Router> 
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/choose-wedding-template" component={ChooseWeddingTemplatePage} />
          <Route exact path="/wedding-invitation" component={WeddingInvitationPage} />
          <Route exact path="/baby-invitation" component={BabyInvitationPage} />

          <Route exact path="/Wedding-Custom-Form" component={WeddingCustomFormPage} />
          <Route exact path="/choose-save-date-template" component={ChooseSaveDateTemplatePage} />
          <Route exact path="/save-the-date" component={SaveDatePage} />
          <Route exact path="/Save-Date-Custom-Form" component={SaveDateCustomFormPage} />
          <Route exact path="/choose-shower-template" component={ChooseShowerTemplatePage} />
          <Route exact path="/choose-baby-template" component={ChooseBabyTemplatePage} />
          <Route exact path="/brit-milah" component={BabyPage} />
          <Route exact path="/baby-Custom-Form" component={BabyCustomFormPage} />
          <Route exact path="/bridal-shower" component={ShowerPage} />
          <Route exact path="/Shower-Custom-Form" component={ShowerCustomFormPage} />
          <Route exact path="/invitation-sample" component={InviteSamplePage} />
          <Route exact path="/RSVP" component={RSVPFormPage} />
          <Route exact path="/Stripe" component={StripeFormPage} />
          <Route exact path="/RSVP-List" component={RSVPTablePage} />
          <Route exact path="/How-To-Pay" component={HowToPay} />
          <Route exact path="/Referrals-Affiliates" component={ComingSoon} />
          <Route exact path="/Become-a-Developer" component={BecomeADeveloper} />
          <Route exact path="/Terms-And-Conditions" component={TermsAndConditions} />
          <Route exact path="/make-payment" component={Payment} />
          <Route exact path="/submit" component={Submit} />
          <Route exact path ="/payment-confirmed"component ={PaymentCompletePage} />
          <Route exact path="/comingsoon" component={ComingSoon} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/test" component={TestPage} />
          <Redirect to="/404"/>
        </Switch>
      </Router>
    );
  }
}

export default App;
