import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, InputLabel, IconButton } from "@material-ui/core";
import { Reply, PhotoCamera, Delete, Backup } from "@material-ui/icons";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Stripe from "../../Stripe/Stripe";


export default function PaymentForm(props) {
  const history = useHistory();
  const location = useLocation();
  const [clientSecret, setClientSecret] = React.useState("");

  var prevBalance = location.state.balance
  
  var perecentage =((3/ 100) * prevBalance).toFixed(2)
  console.log(perecentage)
  perecentage = parseFloat(perecentage) + .30
  console.log(perecentage)

  var total = parseFloat(prevBalance) + parseFloat(perecentage)
  const balance =Math.ceil(total)

  console.log(location)
  const [photoName, setPhotoName] = React.useState('');

  const [isCC, setCC] = React.useState(false);
  const [loadingActive, setLoadingActive] = React.useState(false);

  useEffect(() =>{
    if(location.state.rsvp === true) {
      document.getElementById("RSVPFee").style.backgroundColor = "#E6E6E6";
    }
    if(location.state.customSong === "undefined") {
      document.getElementById("CustomSongFee").style.backgroundColor = "#E6E6E6";
    }
    if(location.state.customDesign === "undefined") {
      document.getElementById("CustomDesignFee").style.backgroundColor = "#E6E6E6";
    }
  })

  const handlePhotoChange = (event) => {
    if (event.target.files[0]) {
      setPhotoName(event.target.files[0].name);
      document.getElementById("clearProof").style.visibility = "visible";
    }
  };

  const clearPaymentChoice = (event) => {
    setPhotoName('');
    document.getElementById("clearProof").style.visibility = "hidden";
    document.getElementById("proofOfPayment").value = "";
  }

  // const validateFields = e => {
  //   e.preventDefault();
  //   window.alert(props.formData.customSong)
  //   if(props.formData.baseSong==='Custom' && props.formData.customSong ==="")
  //   {
  //     window.alert('A custom song must be uploaded otherwise please choose a song from the dropdown list')
  //   } 
  //   else if(props.handleReqObject.template==='custom' && props.formData.customDesign ==="")
  //   {
  //     window.alert('A custom design must be uploaded otherwise please choose another template from the homepage menu')
  //   }
  // }   
  
  const handleBack = e => {
    setCC(false)
    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "none";
    document.getElementById("cardDeckDiv").style.display = "flex";
    document.getElementById("paymentText").innerHTML = "How would you like to Pay?";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "none";
    document.getElementById("buttonDiv").style.display = "none";
    document.getElementById("orDiv").style.display = "block";
    document.getElementById("creditDiv").style.display = "block";
    document.getElementById("stripeDiv").style.display = "none";
  }

  const handleBitcoin = e => {
    setCC(false)
    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "block";
    document.getElementById("cardDeckDiv").style.display = "none";
    document.getElementById("paymentText").innerHTML = "Please send $" + prevBalance + " payment on Bitcoin to NeedForGeeksNY wallet address 3HJVqSVUgNG35VHph1vFh1UrLoepFVFxnD to avoid unnecessary fees. Submit proof of payment below when you are done.";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "flex";
    document.getElementById("buttonDiv").style.display = "block";
    document.getElementById("orDiv").style.display = "none";
    document.getElementById("creditDiv").style.display = "none";
    document.getElementById("stripeDiv").style.display = "none";
  }

  const handlePayPal = e => {
    setCC(false)
    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "block";
    document.getElementById("cardDeckDiv").style.display = "none";
    document.getElementById("paymentText").innerHTML = "Please send $" + prevBalance + " payment on PayPal to billing@NeedForGeeks.com and choose the friend and family option to avoid unnecessary fees. Submit proof of payment below when you are done.";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "flex";
    document.getElementById("buttonDiv").style.display = "block";
    document.getElementById("orDiv").style.display = "none";
    document.getElementById("creditDiv").style.display = "none";
    document.getElementById("stripeDiv").style.display = "none";
  }

  const handleVenmo = e => {
    setCC(false)
    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "block";
    document.getElementById("cardDeckDiv").style.display = "none";
    document.getElementById("paymentText").innerHTML = "Please Venmo $" + prevBalance + " NeedForGeeksNY to avoid unnecessary fees. Submit proof of payment below when you are done.";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "flex";
    document.getElementById("buttonDiv").style.display = "block";
    document.getElementById("orDiv").style.display = "none";
    document.getElementById("creditDiv").style.display = "none";
    document.getElementById("stripeDiv").style.display = "none";
  }

  const handleZelle = e => {
    setCC(false)

    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "block";
    document.getElementById("cardDeckDiv").style.display = "none";
    document.getElementById("paymentText").innerHTML = "Please send $" + prevBalance + " Zelle payment to billing@NeedForGeeks.com to avoid unnecessary fees. Submit proof of payment below when you are done.";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "flex";
    document.getElementById("buttonDiv").style.display = "block";
    document.getElementById("orDiv").style.display = "none";
    document.getElementById("creditDiv").style.display = "none";
    document.getElementById("stripeDiv").style.display = "none";
  }

  const handleCreditCard = e => {
    setCC(true)
    document.getElementById("pricingTextDiv").style.display = "flex";
    document.getElementById("pricingDiv").style.display = "flex";
    document.getElementById("backButton").style.display = "block";
    document.getElementById("cardDeckDiv").style.display = "none";
    document.getElementById("paymentText").innerHTML = "Enter Card Information:";
    document.getElementById("paymentText").style.fontSize = "1.8rem";
    document.getElementById("paymentText").style.paddingBottom = "40px";
    document.getElementById("paymentText").style.paddingTop = "40px";
    document.getElementById("paymentText").style.margin = "0rem";
    document.getElementById("proofDiv").style.display = "none";
    document.getElementById("buttonDiv").style.display = "none";
    document.getElementById("orDiv").style.display = "none";
    document.getElementById("creditDiv").style.display = "none";
    document.getElementById("stripeDiv").style.display = "block";

    axios.post(process.env.REACT_APP_API_URL + '/create-payment-intent', {
      "balance": balance+"00"
    })
    .then((response) => setClientSecret(response.data.clientSecret))
  
      .catch(function (error) {
        console.log(error);
        window.alert("Something went wrong, please contact us or try again soon. Sorry for the inconvenience!")
      });
  }

  const handleSubmit = e => {
    e.preventDefault();
     var imagefile = document.querySelector('#proofOfPayment');
     if(imagefile.files.length === 0 && !isCC)
     {
       window.alert("Proof of Payment must be uploaded")
     }
     else if(isCC)
     {

     }
     else
     {
      setLoadingActive(true)

    var formData = new FormData();
    for (var key in location.state){
      formData.append(key, location.state[key])
    }
      
    formData.append("proofOfPayment", imagefile.files[0]);
    formData.append("local", window.location.href.includes('localhost') ? true:false);
    formData.append("gender", props.gender);

    

    axios.post(process.env.REACT_APP_API_URL + '/profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(function (response) {
        console.log('OK');
        setLoadingActive(false)
       history.replace('/payment-confirmed');
      })
      .catch(function (error) {
        console.log(error);
        window.alert("Something went wrong, please contact us or try again soon. Sorry for the inconvenience!")
      });
    }

  };

  return (
    <div>
      <div
        id="payment-form"
        className="bg-white min-vh-100"
        style={{ border: "2px solid grey", borderRadius: "8px", margin: "10px", backgroundColor: "white"}}
        width="100%"
      >
        <LoadingOverlay active={loadingActive} spinner text="Submitting...">
        <div className="container container-fluid text-center">
          <form
            autoCorrect="off"
            onSubmit={e => handleSubmit(e)}
            id="default-form"
            style={{
              flexDirection: "column",
              borderRadius: "10px",
              maxWidth: "1000px",
              display: "inline-block",
              width: "100%",
              paddingTop: "15px",
              marginTop: 0
            }}
          >
            <div className="form-row" id="pricingTextDiv" style={{display: "flex"}}>
              <div className="form-group col-md">
                <h2 display="block" align="left"
                  style={{
                    paddingTop: "20px",
                    margin: "0rem",
                    paddingBottom: "20px",
                    fontSize: "1.5rem",
                  }}
                >
                  Pricing
                </h2>
              </div>
              <div className="form-group col-md">
                <h2 display="block" align="right"
                  style={{
                    paddingTop: "20px",
                    margin: "0rem",
                    paddingBottom: "20px",
                    fontSize: "1.7rem",
                    color: "#173962"
                  }}
                >
                  Total balance = <span style={{ color: "red" }}>${isCC? balance: prevBalance}</span>
                </h2>
              </div>
            </div>

            <div className="form-row" id="pricingDiv" style={{display: "flex"}}>
              <div id="baseFee" className="pricing col-md" style={{backgroundColor: "#E6E6E6"}}>
                <p style={{fontSize:"16px"}}><b>Base Fee</b></p>
                <p className="card-text"><strike> $300</strike> <span style={{color:"red"}}> $250</span></p>
                <p className="card-text">Invite as many as you want, for one great price!</p>
              </div>
              <div id="CustomDesignFee" className="pricing col-md">
                <p style={{fontSize:"16px"}}><b>Custom Design</b></p>
                <p className="card-text"><strike> +$100</strike> <span style={{color:"red"}}> +50</span></p>
                <p className="card-text">Upload your favorite invite and we'll make it for you!</p>
                <p className="text-muted">+ 5-7 Business Days</p>
              </div>
              <div id="CustomSongFee" className="pricing col-md">
                <p style={{fontSize:"16px"}}><b>Custom Song</b></p>
                <p className="card-text"><strike> +$30</strike> <span style={{color:"red"}}> FREE</span></p>
                <p className="card-text">Upload your favorite song and we'll play it for you!</p>
                <p className="text-muted">+ 1-2 Business Days</p>
              </div>
              <div id="RSVPFee" className="pricing col-md">
                <p style={{fontSize:"16px"}}><b>Allow RSVP</b></p>
                <p className="card-text"> +$50</p>
                <p className="card-text">Allow your invitees to RSVP to your event!</p>
              </div>
              {
                isCC?
                <div  className="pricing col-md" style={{backgroundColor: "#E6E6E6"}}>
                <p style={{fontSize:"16px"}}><b>Processing Fee</b></p>
                <p className="card-text"> +${Math.ceil(perecentage)}</p>
                <p className="card-text">Credit Card Processing Fees</p>
              </div>
              :
              null
              }
            </div>
            <div id="backButton" style={{textAlign:"left", display: "none"}}>
              <Button onClick={e => handleBack(e)} variant="contained" component="span" style={{ backgroundColor: "#1DAEE0", color: "white", margin: "10px" }} size="large" startIcon={<Reply />}>Choose Another Method</Button>
            </div>
            <div className="form-group col-md" id="paymentText">
              <h2 display="block" align="center"
                style={{
                  paddingTop: "40px",
                  margin: "0rem",
                  paddingBottom: "40px",
                  fontSize: "1.8rem",
                }}
              >
                How would you like to Pay?
              </h2>
            </div>
            {/* <div className="card-deck" style={{ display: "flex", justifyContent: "center"}} id="cardDeckDiv">
              <div className="row">
                <div className="card">
                  <img style={{ height: "100px", width: "100px", alignSelf: "center", marginTop: "15px" }} src={require('../../../Footer/Logos/bitcoin.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Bitcoin</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">NeedForGeeksNY accepts bitcoin as a currency! Just send directly to our wallet address <b>3HJVqSVUgNG35VHph1vFh1UrLoepFVFxnD</b></li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <img style={{ height: "100px", width: "100px", alignSelf: "center", marginTop: "15px" }} src={require('../../../Footer/Logos/paypal_nobrand.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">PayPal</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">Use <b>billing@NeedForGeeks.com</b> with PayPal and choose the friend and family option to avoid unnecessary fees.</li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <img style={{ height: "100px", width: "100px", alignSelf: "center", marginTop: "15px" }} src={require('../../../Footer/Logos/venmo.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Venmo</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">The easiest way to pay and avoid all fees. Our VENMO name is <b>NeedForGeeksNY</b></li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <img style={{ height: "100px", width: "100px", alignSelf: "center", marginTop: "15px" }} src={require('../../../Footer/Logos/Zelle.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Zelle QuickPay</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">We accept Zelle Quickpay transfers through Zelle sent to: <b>billing@NeedForGeeks.com</b> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row justify-content-center" id="cardDeckDiv" style={{display: "flex"}}>
              <div className="col-md-3 mt-2 mb-2" style={{width:"150px", maxHeight:"300px"}}>
                <div className="cc-porfolio-image img-raised">
                  <Button onClick={e => handleBitcoin(e)}  style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                    <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../../../Footer/Logos/bitcoin.png')} alt="" />
                      <figcaption>
                        <div className="h4">Bitcoin</div>
                      </figcaption>
                    </figure>
                  </Button>
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-2" style={{width:"150px", maxHeight:"300px"}}>
                <div className="cc-porfolio-image img-raised">
                  <Button onClick={e => handlePayPal(e)} style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                    <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../../../Footer/Logos/paypal_nobrand.png')} alt="" />
                      <figcaption>
                        <div className="h4">PayPal</div>
                      </figcaption>
                    </figure>
                  </Button>
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-2" style={{width:"150px", maxHeight:"300px"}}>
                <div className="cc-porfolio-image img-raised">
                  <Button onClick={e => handleVenmo(e)} style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                    <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../../../Footer/Logos/venmo.png')} alt="" />
                      <figcaption>
                        <div className="h4">Venmo</div>
                      </figcaption>
                    </figure>
                  </Button>
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-2" style={{width:"150px", maxHeight:"300px"}}>
                <div className="cc-porfolio-image img-raised">
                  <Button onClick={e => handleZelle(e)} style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                    <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../../../Footer/Logos/Zelle.png')} alt="" />
                      <figcaption>
                        <div className="h4">Zelle</div>
                      </figcaption>
                    </figure>
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-row" style={{ textAlign: "center", display: "none"}} id="proofDiv">
              <div className="form-group col-md" style={{maxWidth: "200px"}}>
                <InputLabel htmlFor="proofOfPayment" style={{ textAlign: "left" }} >Proof of Payment <span style={{ color: "red" }}>*</span>
                  <input accept="image/*" onChange={handlePhotoChange} style={{ display: 'none' }} id="proofOfPayment" name="proofOfPayment" type="file" />
                  <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Photo</Button>
                </InputLabel>
              </div>
              <div id="clearProof" className="form-group col-md-1" style={{maxWidth: "50px", visibility: "hidden"}}>
                <InputLabel style={{ textAlign: "left" }}></InputLabel>
                <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearPaymentChoice}>
                  <Delete />
                </IconButton>
              </div>
              <div className="form-group col-md-8" style={{maxWidth: "500px"}}>
                <InputLabel style={{ textAlign: "left" }}>Proof of Payment Received</InputLabel>
                <TextField disabled required type="text" id="number" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={photoName} />
              </div>
            </div>
            <div className="form-group" id="buttonDiv" style={{display: "none"}}>
              <Button id="save" variant="contained" style={{ backgroundColor: "#1DAEE0", color: "white", margin: "10px" }} size="large" type="submit" startIcon={<Backup />}>Submit</Button>
            </div>
            <h2 display="block" align="center" id="orDiv"
              style={{
                paddingTop: "20px",
                margin: "0rem",
                paddingBottom: "20px",
                fontSize: "1.5rem",
                display: "block"
              }}
            >
              ----- OR -----
            </h2>
            {/* <div className="card-deck" style={{ display: "flex", justifyContent: "center"}} id="creditDiv">
              <div className="row">
                <div className="card">
                  <img style={{ height: "100px", width: "100px", alignSelf: "center", marginTop: "15px" }} src={require('../../../Footer/Logos/credit-card.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Pay with Credit Card</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">You will be charged a processing fee for choosing this option. </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row" id="creditDiv" style={{ marginBottom: "15px", display: "block"}}>
              <div className="col-md">
                <div className="cc-porfolio-image">
                  <Button className= "img-raised" onClick={e => handleCreditCard(e)} style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                    <figure className="cc-effect" style={{ marginBottom: 0, }}><img height="150px" width="150px" src={require('../../../Footer/Logos/credit-card.png')} alt="" />
                      <figcaption>
                        <div className="h4">Credit Card</div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item" style={{backgroundColor: "transparent"}}>You will be charged a processing fee for choosing this option. </li>
                        </ul>                      
                      </figcaption>
                    </figure>
                  </Button>
                </div>
              </div>
            </div>
            <div id="stripeDiv" style={{ marginBottom: "15px", display: "none"}}>
              <Stripe balance={balance} clientSecret = {clientSecret}/>
            </div>
          </form>
        </div>
        </LoadingOverlay>
      </div>
    </div>
  );
}