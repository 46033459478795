// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDJr0ycWR3AbScnk2EsR6W9vVa0wBnhGx4",
    authDomain: "invitations-51f4f.firebaseapp.com",
    projectId: "invitations-51f4f",
    storageBucket: "invitations-51f4f.appspot.com",
    messagingSenderId: "96667588568",
    appId: "1:96667588568:web:0b67cbcbf32855e1ac84ad",
    measurementId: "G-K5Z0WS4V3Z"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);