import React from "react";
// import { useHistory } from "react-router-dom";
import { Button, makeStyles, Radio, RadioGroup, FormControlLabel, FormLabel, Grid, TextField, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import axios from "axios";
import DeveloperHeader from "./BecomeDeveloperHeader";
import "./Contact.css";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function DeveloperForm() {
  const classes = useStyles();
  // const history = useHistory();

  const [value, setValue] = React.useState('emailme');
  const [photo, setPhoto] = React.useState(null);

  const initializeForm = () => {
    document.getElementById("email").setAttribute("required", "");
    document.getElementById("number").removeAttribute("required");
  }

  window.onload = initializeForm;

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "emailme") {
      document.getElementById("email").setAttribute("required", "");
      document.getElementById("number").removeAttribute("required");
    }
    else {
      document.getElementById("email").removeAttribute("required");
      document.getElementById("number").setAttribute("required", "");
    }
  };

  const handlePhotoChange = (event) => {
    if (event.target.files[0]) {
      // setPhoto(event.target.files[0]);
      setPhoto(event.target.files[0]);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    try {
      const firstName = document.getElementById("firstname").value;
      const lastName = document.getElementById("lastname").value;
      const email = document.getElementById("email").value;
      const number = document.getElementById("number").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("message").value;

      axios({
        method: "POST",
        url: "https://formspree.io/f/xrgrpkkr",
        data: {
          firstName: firstName,
          lastName: lastName,
          number: number,
          email: email,
          image: photo,
          subject: subject,
          message: message,
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    catch (e) {
      console.log(e.message);
    }
  };
  window.document.title = "NeedForGeeks | Join the Team";

  return (
    <div>
      <DeveloperHeader />
      <div
        id="becomeDev"
        className="jumbotron jumbotron-fluid min-vh-100 m-0" data-spy="scroll" data-target="#mainNavbar" data-offset="0"
        style={{ background: "white" }}
        width="100%"
      // style={{backgroundImage: `url(${Background})`}}
      >
        <div className="container container-fluid text-center">
          <Typography variant="h2" display="block" align="center" id="contact-us"
            style={{
              paddingTop: "4rem",
              margin: "0rem",
              fontSize: "2.5rem",
            }}
          >
            Become a Developer
          </Typography>
          <form
            autoCorrect="off"
            onSubmit={e => handleSubmit(e)}
            id="contact-form"
            style={{
              backgroundColor: "transparent",
              flexDirection: "column",
              padding: "15px",
              borderRadius: "10px",
              maxWidth: "650px",
              display: "inline-block",
              width: "100%",
            }}
          >
            <div className="form-row">
              <div className="col">
                <TextField required label="First Name" type="text" margin="normal" variant="outlined" id="firstname" placeholder="First Name" style={{ width: "100%" }} />
              </div>
              <div className="col">
                <TextField required label="Last Name" type="text" id="lastname" margin="normal" variant="outlined" placeholder="Last Name" style={{ width: "100%" }} />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flexWrap: "wrap", paddingTop: "5px" }}>
              <FormLabel component="legend">How should I reach out?</FormLabel>
              <RadioGroup name="method" value={value} >
                <Grid container>
                  <Grid item>
                    <FormControlLabel value="emailme" control={<Radio />} label="Email Us!" onClick={handleChange} />
                  </Grid>

                </Grid>
              </RadioGroup>
            </div>
            <div className="form-row" style={{ textAlign: "center" }}>
              <div className="form-group col-md">
                <TextField label="Email Address" type="email" id="email" variant="outlined" placeholder="Email Address" style={{ width: "100%" }} />
              </div>
              <div className="form-group col-md">
                <TextField label="Phone Number" type="tel" id="number" variant="outlined" placeholder="Phone Number" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-row" style={{ textAlign: "center" }}>
              <div className="form-group col-md">
                <TextField label="Subject" type="text" id="subject" variant="outlined" placeholder="Subject" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-group">
              <TextField required label="Message" type="text-area" id="message" multiline rows={4} variant="outlined" placeholder="Message" style={{ width: "100%" }} />

            </div>
            <div className="form-group">
              <FormLabel component="legend">Attach Resume:
            <br />
                <br />  <br />
                <label style={{ width: "100%" }} htmlFor="upload-photo">
                  <input onChange={handlePhotoChange} accept="image/*" style={{ display: 'none' }} id="upload-photo" type="file" />
                  <Button required style={{ width: "100%", height: "56px" }} color="secondary" variant="outlined" component="span">Upload Photo</Button>
                </label>
              </FormLabel>
            </div>

            <div className="form-group">
              <Button style={{ borderColor: "#1DAEE0", color: "#1DAEE0" }} variant="outlined" color="primary" size="large" type="submit" className={classes.button} endIcon={<Send />}>Apply Now</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}