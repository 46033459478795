// import WeddingForm from "./WeddingForm";
import React from "react";
import SideBySide from "./SideBySide";
// var converter = require('number-to-words');
class ShowTemplate extends React.Component {
  constructor(props) {
    super();
    this.template = props.template
  }
  componentDidMount() {

  }
  render() {
    return (
        <SideBySide template={this.template} />
    );
  }
}

export default ShowTemplate;