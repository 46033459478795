import React from "react";
import {Description, TextFormat } from "@material-ui/icons";


const TextTab = () => {
  return (
    <div className='row p-3'>
        <ul className="nav nav-tabs tabs-padding" id="textTab" role="tablist">
          <div className="row">
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<Description />}</div>
              </div>
              <div className="row">
                <p>Each template has a pre-formatted wording template that matches your selected design. These wording templates can be completely changed, but serve as a starting point to get the creative juices flowing.</p>
              </div>
            </div>
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<TextFormat />}</div>
              </div>
              <div className="row">
                <p>Each template features beautiful fonts, themed text colors, line spacing, and sized with our advanced designers.</p>
              </div>
            </div>
          </div>
        </ul>
    </div>
  );
};

export default TextTab;
