import BabyForm from "./Form";
import { Button } from "@material-ui/core";
import { Reply } from "@material-ui/icons";
import React from "react";
import Classic from "./BabyTemplates/Classic";
import Wreath from "./BabyTemplates/Wreath";
import Sakura from "./BabyTemplates/Sakura";
import GoldDots from "./BabyTemplates/GoldDots";
import GoldGlitter from "./BabyTemplates/GoldGlitter";
import MarsalaFloral from "./BabyTemplates/MarsalaFloral";
import SilverGlitter from "./BabyTemplates/SilverGlitter";
import CustomInvitation from "./BabyTemplates/CustomInvitation";
import FormHeader from "../assets/resources/FormHeader";
import CustomBabyForm from "./CustomForm";
import "./BabyTemplates/CSS/Medias.css";

var converter = require('number-to-words');

class SideBySide extends React.Component {
   
  constructor(props) {
    super();
    // eslint-disable-next-line no-extend-native
    String.prototype.capitalize = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }
    this.template = props.template;
    this.requestLine1 = "With Gratidute to Hashem"
    this.requestLine2 = "We would like to cordially invite you to our son's"
    this.womanName = 'Brit Milah'
    this.day = "Monday"
    this.date = 'the Twenty-ninth of November'
    this.year = "Two thousand and twenty one"
    this.time = "Five thirty in the evening"
    this.venue = "Kol Yaakov"
    this.venueForMaps = ''
    this.venueAddress = "1703 McDonald Avenue"
    this.cityState = "Brooklyn, New York"
    this.phone = " 1 (347) 280-3596"
    this.link = 'https://www.google.com/maps/search/?api=1&query=kol+yaakov'
    this.footerMessage1 = `Joe and Rachel Smith`
    this.footerMessage2 = `Honored GrandParents`
    this.endingNote = 'Jack and Jill Smith'
    this.changed = false;
    this.brideInitial = this.womanName.charAt(0).capitalize();
    this.reqObject =
    {
      envelopeColor:"black",
      isSideBySide:true,
      loadingActive:false,
      customDesign:'',
      template: this.template,
      requestLine1: this.requestLine1,
      requestLine2: this.requestLine2,
      womanName: this.womanName,
      day: this.day,
      date: this.date,
      year: this.year,
      time: this.time,
      brideInitial : this.womanName.charAt(0).capitalize(),
      venue: this.venue,
      venueForMaps: this.venueForMaps,
      venueAddress: this.venueAddress,
      cityState: this.cityState,
      phone: this.phone,
      link: this.link,
      footerMessage1: this.footerMessage1,
      footerMessage2: this.footerMessage2,
      endingNote: this.endingNote,
      changed: this.changed,
      invitationType: "Baby"
    }
    this.handleReqObject = this.handleReqObject.bind(this);
  }
  ChooseTemplate() {
    window.location.reload();
  }
  getHours(date) {
    return date.getHours() % 12 || 12;
  }
  handleReqObject = (value) => {
    // eslint-disable-next-line
    String.prototype.capitalize = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }


    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    var d = new Date(value.eventDate);

    var dayName = days[d.getDay()];
    var date = converter.toOrdinal(d.getUTCDate());
    console.log(value.eventDate)
    this.day = dayName;
    this.date = "the " + date + " of " + d.toLocaleString('default', { month: 'long' });
    this.year = converter.toWords(d.getFullYear()).split(',').join(' and ').capitalize();
    var hours = d.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am';
    var timeOfDay = ampm.includes("am") ? "morning" : "evening";
    this.time = converter.toWords(this.getHours(d)) + " " + converter.toWords(d.getMinutes()) + " in the " + timeOfDay;
    var newTime = this.time.replace("zero", "o'clock");

    this.reqObject = {
      template: this.template,
      requestLine1: value.requestLine1,
      requestLine2: value.requestLine2,
      womanName: value.womanName,
       dayName :days[d.getDay()],
       date : "the " + date + " of " + d.toLocaleString('default', { month: 'long' }),
      
      day :dayName,
      //date : "the " + date + " of " + d.toLocaleString('default', { month: 'long' }),
      year : converter.toWords(d.getFullYear()).split(',').join(' and ').capitalize(),
      hours : d.getHours(),
      timeOfDay : ampm.includes("am") ? "morning" : "evening",
      time : newTime,
      loadingActive: value.loadingActive,
      venue: value.venue,
      envelopeColor:value.envelopeColor,
      venueForMaps: value.venueForMaps,
      venueAddress: value.venueAddress,
      cityState: value.cityState,
      phone: value.phone,
      link: value.link,
      footerMessage1: value.footerMessage1,
      footerMessage2: value.footerMessage2,
      endingNote: value.endingNote,
      changed: value.changed,
      invitationType: "Baby"
    }

    this.setState((prevState, props) => ({
      changed: !value.changed,

    }));
    console.log(this.changed)
    console.log(this.reqObject.brideParents + 'ppppp')
  }
 
  render() {
    return (
      <div id="side-by-side-page" style = {{margin: 0,  padding: 0,width:"100%"}} >
         
        <FormHeader />
        <Button variant="contained" onClick={() => this.ChooseTemplate()} style={{ backgroundColor: "#1DAEE0", color: "white", margin:"10px"}} size="large" startIcon={<Reply />}>CHOOSE ANOTHER TEMPLATE</Button>
        <div className="text-center" >
          <div className="form-row">
            <div className="form-group col-md-5">
           { this.template === 'custom' ?
            <CustomBabyForm reqObject={this.reqObject} handleReqObject={this.handleReqObject} template={this.template} />
            :<BabyForm reqObject={this.reqObject} handleReqObject={this.handleReqObject} template={this.template} />
           
            }
            </div>
            {this.template === 'classic' ?
              <div id="letters" className="col-md classic-baby-style myDiv">
                <div className="watermark"></div>
                <Classic reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
            this.template === 'goldDots' ?
              <div id="letters" className="col-md goldDots-baby-style myDiv">
                <div className="watermark"></div>
                <GoldDots reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
            this.template === 'goldGlitter' ?
              <div id="letters" className="col-md goldGlitter-baby-style myDiv">
                <div className="watermark"></div>
                <GoldGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
            this.template === 'wreath' ?
              <div className="col-md wreath-baby-style myDiv">
                <div className="watermark"></div>
                <Wreath reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
            this.template === 'silverGlitter' ?
              <div id="letters" className="col-md silverGlitter-baby-style myDiv">
                <div className="watermark"></div>
                <SilverGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} />
              </div>
            :
            this.template === 'marsalaFloral' ?
              <div className="col-md marsalaFloral-baby-style myDiv">
                <div className="watermark"></div>
                <MarsalaFloral reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
              </div>
            :
            this.template === 'custom' ?
              <div className="col-md sakura-baby-style myDiv">
                <div className="watermark"></div>
                <CustomInvitation reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
              </div>
            :
              <div className="col-md sakura-baby-style myDiv">
                <div className="watermark"></div>
                <Sakura reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSideBySide={true} isSakura={true} />
              </div>
            }
          </div>
        </div>
        
      </div>
    );
  }
}

export default SideBySide;