// import WeddingForm from "./WeddingForm";
import React from "react";
import { Button } from "@material-ui/core";
import ShowTemplate from "./ShowTemplate";
import ChooseTemplateHeader from "../assets/resources/ChooseTemplateHeader"
// var converter = require('number-to-words');
class ChooseTemplate extends React.Component {
  constructor() {
    super();
    this.template = "";
    this.changed = false;
  }
  componentDidMount() {

  }
  ChangeTemplate(word) {
    this.template = word;
    this.setState({ changed: true })
    window.scrollTo(0, 0)
  }
  render() {

    if (this.template !== "") {
      return (
        <ShowTemplate template={this.template} />
      );
    }

    return (
      <div >
        <ChooseTemplateHeader />
        <div className="page-content">
          <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="templatesPage">
            <div className="container">
              <div className="tab-content gallery mt-5">
                <div className="tab-pane active">
                  <div className="ml-auto mr-auto">
                    <div className="row" style={{marginTop: "15px"}}>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("classic")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/classic.png')}  style={{height:"800px"}} alt="" />
                              <figcaption>
                                <div className="h4">Classic</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("sakura")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/sakura.png')}   style={{height:"800px"}} alt="" />
                              <figcaption>
                                <div className="h4">Sakura</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: "15px"}}>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("goldDots")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/goldDots.png')}   style={{height:"800px"}} alt="" />
                              <figcaption>
                                <div className="h4">Gold Dots</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("wreath")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/wreath.png')} style={{height:"800px", width:"600px"}} alt="" />
                              <figcaption>
                                <div className="h4">Gold Wreath</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: "15px"}}>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("marsalaFloral")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/marsalaFloral.png')}   style={{height:"800px"}} alt="" />
                              <figcaption>
                                <div className="h4">Marsala Burgundy Watercolor Floral</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("silverGlitter")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/silverGlitter.png')} style={{height:"800px", width:"600px"}} alt="" />
                              <figcaption>
                                <div className="h4">Silver Glitter</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: "15px"}}>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("goldGlitter")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Wedding/goldGlitter.png')} style={{height:"800px", width:"600px"}} alt="" />
                              <figcaption>
                                <div className="h4">Rose Gold Glitter Flowers</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                          <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" onClick={() => this.ChangeTemplate("custom")}>
                            <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('../assets/img/Other/CustomDesign.png')} style={{height:"800px"}} alt="" />
                              <figcaption>
                                <div className="h4">Place your pre-made Design</div>
                              </figcaption>
                            </figure>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: "15px"}}>
                      <h2 display="block" align="center"
                        style={{
                          paddingTop: "20px",
                          fontSize: "1.5rem",
                        }}
                      >
                        Don't Like what you see? Click here to send us a design you love and we'll design it for you from scratch!
                      </h2>
                    </div>
                    <div className="row" style={{justifyContent: "center"}}>
                      <h4>
                        <a className="pricing" role="button" href="/Wedding-Custom-Form" style={{padding: "7px", borderColor:"#173962", color: "#173962"}}>  
                          Design it Yourself!
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChooseTemplate;