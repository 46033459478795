import React from 'react';
import CalanderButton from './CalanderButton';
//  import { Link } from 'react-router-dom';
import '../css/Navigation.css';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import RegistryLinksPopUp from './RegistryLinksPopUp'
/* eslint-disable */
var buttonText = "Registry/Links";
/* eslint-disable */
export default class EnvelopeLinks extends React.Component {
  constructor(props) {
    super(props);
    this.reqObject=props.reqObject;
    this.changed = true;
 
  };
  state = {
    showPopup: false,
    open: false
    };


    openPopupHandler = () => {
      console.log(this.state.showPopup)
      this.state.showPopup = true;
      this.setState({showPopup: true});
      console.log(this.state.showPopup)
    }
 
    
   handleClickOpen = () => {
      console.log("D")
      this.setState({open: true});
  };

   handleClose = () => {
    this.setState({open: false});
  };
  
  render() {
    console.log(this.reqObject )
    var buttonText = this.reqObject.registryLinks === null || this.reqObject.registryLinks == "" || 
     this.reqObject.registryLinks == undefined ? "Add to Calendar" : "Registry/Links";
     var height = 60;
     var width = 250;
     var border = "50px";
     var just= "center"
     if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) 
     {
      buttonText = ''
      width = 20
      height=50
      border ="0px"
      just="right"
     }
  
  return (
    
    <div className="linksEnvelope">

      <div>
      <Button
      onClick={this.handleClickOpen}
    style={{
      marginBottom:border,
       borderWidth:1,
       borderColor:'rgba(0,0,0,0.2)',
       alignItems:just,
       justifyContent:just,
       width:width,
       height:height,
       backgroundColor:'#1DAEE0',
       borderRadius:30,
       color: "white",
       fontSize:"13pt"
     }}
 >
 <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
  <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
</svg>
&nbsp;&nbsp;&nbsp;{buttonText}
   </Button>
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle style={{color: "#173962"}}>What would you like to do?</DialogTitle>
        <DialogContent  id = "supbro" >
          <form style={{  display: 'flex',flexWrap: 'wrap'}} > 
         {/*  <form style={{  display: 'flex'}} >*/}
          {/* <FormControl style={{ margin: 1,minWidth: 120}} >
          <Link className="btn btn-outline-light" role="button" to="/IT" style={{backgroundColor: "#698889"}}>Send Gift</Link>
          </FormControl> */}
          {this.props.reqObject.registryLinks!='' ?
          <FormControl style={{ margin: 1,minWidth: 120}} >
          <RegistryLinksPopUp reqObject={this.reqObject}/>
          </FormControl>
        :null  
        }
            <FormControl style= {{ margin:1,minWidth: 120}}>
            <CalanderButton reqObject = {this.reqObject}/>
            </FormControl>

          </form>
        </DialogContent>
        <DialogActions>
          <Button style={{color: "#173962"}} onClick={this.handleClose} color="primary">
            Close
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>


    </div>
  );
  }
}