import React from "react";

import '../css/sbsEnvelopeCss.scss';


export default class EnvelopeSideBySide extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    
  }
  state = {
    brideInitial : this.props.brideName?.charAt(0)?.capitalize(),
    groomInitial : this.props.groomName?.charAt(0)?.capitalize(),
    initials:''
  };
   count =0;
  componentWillUpdate() {
    
    if(this.count===0){
      this.count++;
      console.log(this.props.groomName)
      
      
      if(this.props.brideName != null && this.props.groomName!=null)
      {
        
      this.setState({brideInitial: this.props.brideName.charAt(0).capitalize(),
        groomInitial: this.props.groomName.charAt(0).capitalize(),
        initals: this.props.brideInitial + '|' + this.state.groomInitial});
      }
      else if(this.props.brideName != null && this.props.groomName==null)
      {
        console.log('hey')
        console.log(this.props.brideName)
        if(this.props.brideName.capitalize().includes('BOY')|| this.props.brideName.capitalize().includes('GIRL'))
      {
        this.setState({brideInitial: this.props.brideName.charAt(0).capitalize()});
      }
      else
      {
        this.setState({brideInitial: this.props.brideName.charAt(0).capitalize(),
          initals: this.props.brideInitial});
      }
     }
      else if(this.props.brideName == null && this.props.groomName!=null)
      {
        this.setState({
          groomInitial: this.props.groomName.charAt(0).capitalize(),
          initals: this.state.groomInitial});
      }
      

    }
    
  }
 
  render() {
    return (
      <div>
         <div className="form-row" style={{height:"200px",width:"100%"}}>
        <div className="envContainer" style={{height:"200px",minHeight:"200px"}} >
          <div className="content">
            <div id="envelope" className="envelope" >
              <div  id="stickerEnvelope" className="my-circle" title="KM">{this.state.initials}</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  };
}