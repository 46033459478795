import React from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, InputLabel, Select, FormControlLabel, Checkbox, IconButton } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Visibility, PhotoCamera, Audiotrack, Delete, Backup } from "@material-ui/icons";
import LoadingOverlay from "react-loading-overlay";
import SongMenuItem from "../assets/resources/SongMenuItem";
import RegistryLinks from "../assets/resources/registryLinks";
import EnvelopeSideBySide from "../assets/resources/EnvelopeSideBySide";
import { SketchPicker } from 'react-color';
import { format } from 'date-fns';

const GreenCheckbox = withStyles({
  root: {
    color: "#1DAEE0",
    '&$checked': {
      color: "#1DAEE0",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Form(props) {
  const [eventDate, setEventDate] = React.useState(new Date());
  const [state, setState] = React.useState('New York');
  // const [photoName, setPhotoName] = React.useState('');
  const [customDesignName, setCustomDesignName] = React.useState(null);
  const [baseSong, setBaseSong] = React.useState('');
  const [rsvp, setRSVP] = React.useState(false);
  const [songName, setSongName] = React.useState(null);
  const [registryLinks, setRegistryLinks] = React.useState("");

  const [groomName, setGroomName] = React.useState("Romeo");
  const [brideName, setBrideName] = React.useState("Juliet");
  const [envelopeColor, setEnvelopeColor] = React.useState("black");

  const [loadingActive, setLoadingActive] = React.useState(false);
  // const [balance, setBalance] = React.useState(300);
  const [balance, setBalance] = React.useState(250);

  const [local, setLocal] = React.useState(false);

  const history = useHistory();

  const handleRegistryLinkChange = (list) => {
    console.log("list is ")
    console.log(list)
   var map =  list.map( (a, i) => `${a.registryLabel}|${a.registryURL}`).join(',');
    setRegistryLinks(map);
  };
 
  const handleRSVPChange = (event) => {
    setRSVP(event.target.checked);
    if (event.target.checked === true) {
      setBalance(balance + 50);
      document.getElementById("RSVPFee").style.backgroundColor = "#E6E6E6";
    }
    else {
      setBalance(balance - 50);
      document.getElementById("RSVPFee").style.backgroundColor = "transparent";
    }
  };

  const handleEventDateChange = (date) => {
    setEventDate(date);
    console.log(format(date, 'yyyy-MM-dd HH:mm'));
  };

  // const handlePhotoChange = (event) => {
  //   if (event.target.files[0]) {
  //     setPhotoName(event.target.files[0].name);
  //     document.getElementById("clearProof").style.visibility = "visible";
  //   }
  // };

  // const clearPaymentChoice = (event) => {
  //   setPhotoName('');
  //   document.getElementById("clearProof").style.visibility = "hidden";
  //   document.getElementById("proofOfPayment").value = "";
  // }
  const handleCustomDesignChange = (event) => {
    if (event.target.files[0]) {
      setCustomDesignName(event.target.files[0].name);
      document.getElementById("clearDesign").style.visibility = "visible";
      console.log(event.target.files[0].filepath + 'uck')
      if (event.target.files[0].name !== '') {
        setBalance(balance + 50);
        document.getElementById("CustomDesignFee").style.backgroundColor = "#E6E6E6";
      }
      else {
        setBalance(balance - 50);
        document.getElementById("CustomDesignFee").style.backgroundColor = "transparent";
      }
    }
  };

  const clearDesignChoice = (event) => {
    setCustomDesignName('');
    document.getElementById("clearDesign").style.visibility = "hidden";
    setBalance(balance - 50);
    document.getElementById("CustomDesignFee").style.backgroundColor = "transparent";
    document.getElementById("customDesign").value = "";
  }

  const handleSongChange = (event) => {
    if (event.target.files[0]) {
      setSongName(event.target.files[0].name);
      document.getElementById("clearSong").style.visibility = "visible";
    
      if (event.target.files[0].name !== '') {
        setBalance(balance + 0);
        document.getElementById("CustomSongFee").style.backgroundColor = "#E6E6E6";
      }
      else {
        setBalance(balance - 0);
        document.getElementById("CustomSongFee").style.backgroundColor = "transparent";
      }
    }
  };

  const clearSongChoice = (event) => {
    setSongName('');
    document.getElementById("clearSong").style.visibility = "hidden";
    // setBalance(balance - 30);
    setBalance(balance - 0);
    document.getElementById("CustomSongFee").style.backgroundColor = "transparent";
    document.getElementById("customSong").value = "";
  }

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleBaseSongChange = (event) => {
    setBaseSong(event.target.value);
    if(event.target.value === "Custom") {
      document.getElementById("customSongReceived").setAttribute("required", "");
      document.getElementById("redSong").innerHTML = "*";
      document.getElementById("red")
    }
    else {
      document.getElementById("customSongReceived").removeAttribute("required");
      document.getElementById("redSong").innerHTML = "";
    }
  };

  const previewForm = () => {

    const groomParents = document.getElementById("groomParents").value;
    const brideParents = document.getElementById("brideParents").value;
    const requestLine1 = document.getElementById("requestLine1").value;
    const requestLine2 = document.getElementById("requestLine2").value;
    const brideName = document.getElementById("brideName").value;
    const groomName = document.getElementById("groomName").value;
    const venueName = document.getElementById("venueName").value;
    const venueAddress = document.getElementById("venueAddress").value;
    const city = document.getElementById("city").value;
    const footerMessage1 = document.getElementById("footerMessage1").value;
    const footerMessage2 = document.getElementById("footerMessage2").value;
    const endingNote = document.getElementById("endingNote").value;
    const bottomLeft = document.getElementById("bottomLeft").value;
    const bottomRight = document.getElementById("bottomRight").value;
    const number = document.getElementById("number").value;
    
    setGroomName(groomName);
    setBrideName(brideName);

    console.log(baseSong)
    var designFile=document.querySelector('#customDesign');
    console.log(designFile)
    props.handleReqObject({
      envelopeColor:envelopeColor,
      groomParents: groomParents,
      brideParents: brideParents,
      requestLine1: requestLine1,
      requestLine2: requestLine2,
      womanName: brideName,
      manName: groomName,
      venue: venueName,
      venueAddress: venueAddress,
      cityState: city + "," + state,
      footerMessage1: footerMessage1,
      footerMessage2: footerMessage2,
      endingNote: endingNote,
      bottomLeft: bottomLeft,
      bottomRight: bottomRight,
      phone: number,
      eventDate: eventDate,
      template: props.template
    })
    
  }
 const handleChangeComplete = (color) => {
   setEnvelopeColor(color.hex);
    document.documentElement.style.setProperty("--base", color.hex);
    document.documentElement.style.setProperty("--base-dark", color.hex);
    document.documentElement.style.setProperty("--base-light", color.hex);
    document.documentElement.style.setProperty("--letter", color.hex);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // var imagefile = document.querySelector('#proofOfPayment');
    var songfile = document.querySelector('#customSong');
    var designFile = document.querySelector('#customDesign');
    console.log(baseSong)
    // if(imagefile.files.length === 0)
    // {
    //   window.alert("Proof of Payment must be uploaded")
    // }
   if(baseSong==='Custom' && songfile.files.length ===0)
    {
      window.alert('A custom song must be uploaded otherwise please choose a song from the dropdown list')
    } 
    else if(props.template==='custom' && designFile.files.length ===0)
    {
      window.alert('A custom design must be uploaded otherwise please choose another template from the homepage menu')
    }
    else
    {
      setLoadingActive(true)
    const groomParents = document.getElementById("groomParents").value;
    const brideParents = document.getElementById("brideParents").value;
    const requestLine1 = document.getElementById("requestLine1").value;
    const requestLine2 = document.getElementById("requestLine2").value;
    const brideName = document.getElementById("brideName").value;
    const groomName = document.getElementById("groomName").value;
    const venueName = document.getElementById("venueName").value;
    const venueAddress = document.getElementById("venueAddress").value;
    const city = document.getElementById("city").value;
    const footerMessage1 = document.getElementById("footerMessage1").value;
    const footerMessage2 = document.getElementById("footerMessage2").value;
    const endingNote = document.getElementById("endingNote").value;
    const bottomLeft = document.getElementById("bottomLeft").value;
    const bottomRight = document.getElementById("bottomRight").value;
    const email = document.getElementById("email").value;
    const number = document.getElementById("number").value;
    const recommendation = document.getElementById("recommendations").value;
    const customInstructions = document.getElementById("customInstructions").value;
   
    var paramObject = {
      "invitationType": props.reqObject.invitationType,
      "envelopeColor": envelopeColor,
      "registryLinks": registryLinks,
      //"proofOfPayment": imagefile.files[0]);
      "customSong": songfile.files[0],
      "customDesign": designFile.files[0],
      "rsvp": rsvp,
      "customInstructions": customInstructions,
      "recommendation": recommendation,
      "baseSong": baseSong,
      "state": state,
      "groomParents": groomParents,
      "brideParents": brideParents,
      "requestLine1": requestLine1,
      "requestLine2": requestLine2,
      "brideName": brideName,
      "groomName": groomName,
      "eventDate": eventDate,
      "venueName": venueName,
      "venueAddress": venueAddress,
      "city": city,
      "cityState": city + ", " + state,
      "footerMessage1": footerMessage1,
      "footerMessage2": footerMessage2,
      "endingNote": endingNote,
      "bottomLeft": bottomLeft,
      "bottomRight": bottomRight,
      "email": email,
      "number": number,
      "template": props.template,
      "balance": balance,
      "isActive":false
  
    }
   
    history.push({
      pathname: '/make-payment',
      state: paramObject,
    });

   
     }
   
  
  };

  return (
    <div
      id="default-form-page"
      className="bg-transparent min-vh-100"
      style={{ border: "2px solid grey", borderRadius: "8px", margin: "10px" }}
      width="100%"
    >
       <LoadingOverlay active={loadingActive} spinner text="Submitting...">
      <div className="container container-fluid text-center">
        <form
          autoCorrect="off"
          onSubmit={e => handleSubmit(e)}
          id="default-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
            paddingTop: "15px",
            marginTop: 0
          }}
        >
          <h2 display="block" align="left"
            style={{
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Invitation Header
          </h2>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Bride Parents <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="brideParents" style={{ marginTop: 0, width: "100%" }} defaultValue="Mr. and Mrs. Mother LastName" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Groom Parents <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="groomParents" variant="outlined" style={{ marginTop: 0, width: "100%" }} defaultValue="Mr. and Mrs. Father LastName" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Request Line 1 <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="requestLine1" style={{ marginTop: 0, width: "100%" }} defaultValue="Request the honour of your presence" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Request Line 2 <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="requestLine2" variant="outlined" style={{ marginTop: 0, width: "100%" }} defaultValue="at the marriage of their children" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Bride's Name <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="brideName" style={{ marginTop: 0, width: "100%" }} defaultValue="Juliet" />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Groom's Name <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="groomName" variant="outlined" style={{ marginTop: 0, width: "100%" }} defaultValue="Romeo" />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Event Details
          </h2>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-5">
              <InputLabel style={{ textAlign: "left" }}>Date/Time of Event <span style={{ color: "red" }}>*</span></InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  color="#1DAEE0"
                  inputVariant="outlined"
                  value={eventDate}
                  ampm={true}
                  onChange={handleEventDateChange}
                  id="eventDate"
                  format="MM/dd/yyyy hh:mm a"

                  disablePast
                  style={{ width: "100%" }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    'backgroundColor': '#1DAEE0',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Name of Venue <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="venueName" variant="outlined" style={{ width: "100%" }} defaultValue="Kol Yaakov" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Venue Address <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="venueAddress" variant="outlined" style={{ width: "100%" }} defaultValue="1703 McDonald Avenue" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>City <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="city" variant="outlined" style={{ width: "100%" }} defaultValue="Brooklyn" />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>State <span style={{ color: "red" }}>*</span></InputLabel>
              <Select
                value={state}
                onChange={handleStateChange}
                required type="text" variant="outlined" id="state" style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select State</em>
                </MenuItem>
                <MenuItem value={"Alabama"}>Alabama</MenuItem>
                <MenuItem value={"Alaska"}>Alaska</MenuItem>
                <MenuItem value={"Arizona"}>Arizona</MenuItem>
                <MenuItem value={"Arkansas"}>Arkansas</MenuItem>
                <MenuItem value={"California"}>California</MenuItem>
                <MenuItem value={"Colorado"}>Colorado</MenuItem>
                <MenuItem value={"Connecticut"}>Connecticut</MenuItem>
                <MenuItem value={"Delaware"}>Delaware</MenuItem>
                <MenuItem value={"Florida"}>Florida</MenuItem>
                <MenuItem value={"Georgia"}>Georgia</MenuItem>
                <MenuItem value={"Hawaii"}>Hawaii</MenuItem>
                <MenuItem value={"Idaho"}>Idaho</MenuItem>
                <MenuItem value={"Illinois"}>Illinois</MenuItem>
                <MenuItem value={"Indiana"}>Indiana</MenuItem>
                <MenuItem value={"Iowa"}>Iowa</MenuItem>
                <MenuItem value={"Kansas"}>Kansas</MenuItem>
                <MenuItem value={"Kentucky"}>Kentucky</MenuItem>
                <MenuItem value={"Louisiana"}>Louisiana</MenuItem>
                <MenuItem value={"Maine"}>Maine</MenuItem>
                <MenuItem value={"Maryland"}>Maryland</MenuItem>
                <MenuItem value={"Massachusetts"}>Massachusetts</MenuItem>
                <MenuItem value={"Michigan"}>Michigan</MenuItem>
                <MenuItem value={"Minnesota"}>Minnesota</MenuItem>
                <MenuItem value={"Mississippi"}>Mississippi</MenuItem>
                <MenuItem value={"Missouri"}>Missouri</MenuItem>
                <MenuItem value={"Montana"}>Montana</MenuItem>
                <MenuItem value={"Nebraska"}>Nebraska</MenuItem>
                <MenuItem value={"Nevada"}>Nevada</MenuItem>
                <MenuItem value={"New Hampshire"}>New Hampshire</MenuItem>
                <MenuItem value={"New Jersey"}>New Jersey</MenuItem>
                <MenuItem value={"New Mexico"}>New Mexico</MenuItem>
                <MenuItem value={"New York"}>New York</MenuItem>
                <MenuItem value={"North Carolina"}>North Carolina</MenuItem>
                <MenuItem value={"North Dakota"}>North Dakota</MenuItem>
                <MenuItem value={"Ohio"}>Ohio</MenuItem>
                <MenuItem value={"Oklahoma"}>Oklahoma</MenuItem>
                <MenuItem value={"Oregon"}>Oregon</MenuItem>
                <MenuItem value={"Pennsylvania"}>Pennsylvania</MenuItem>
                <MenuItem value={"Rhode Island"}>Rhode Island</MenuItem>
                <MenuItem value={"South Carolina"}>South Carolina</MenuItem>
                <MenuItem value={"South Dakota"}>South Dakota</MenuItem>
                <MenuItem value={"Tennessee"}>Tennessee</MenuItem>
                <MenuItem value={"Texas"}>Texas</MenuItem>
                <MenuItem value={"Utah"}>Utah</MenuItem>
                <MenuItem value={"Vermont"}>Vermont</MenuItem>
                <MenuItem value={"Virginia"}>Virginia</MenuItem>
                <MenuItem value={"Washington"}>Washington</MenuItem>
                <MenuItem value={"West Virginia"}>West Virginia</MenuItem>
                <MenuItem value={"Wisconsin"}>Wisconsin</MenuItem>
                <MenuItem value={"Wyoming"}>Wyoming</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Footer Message Line 1<span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text-area" id="footerMessage1" variant="outlined" inputProps={{ maxLength: 500 }} style={{ width: "100%" }}
                defaultValue="Can't wait to celebrate auspicious moment of our family with you!" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Footer Message Line 2<span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text-area" id="footerMessage2" variant="outlined" inputProps={{ maxLength: 500 }} style={{ width: "100%" }}
                defaultValue="Just a ping away for any queries: 1 (347) 280-3596" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Ending Note <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="endingNote" variant="outlined" style={{ marginTop: 0, width: "100%" }} defaultValue="Join us in our happiness!" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Event Formality <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="bottomLeft" style={{ marginTop: 0, width: "100%" }} defaultValue="Black Tie" />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Other <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="bottomRight" variant="outlined" style={{ marginTop: 0, width: "100%" }} defaultValue="Ceremony promptly at 6:30 pm" />
            </div>
          </div>
          <div className="form-group">
            <Button id="preview" variant="contained" style={{ backgroundColor: "#1DAEE0", color: "white", margin: "10px" }} size="large" startIcon={<Visibility />} onClick={() => previewForm()}>Preview</Button>
          </div>
          <div className="form-row ">
            <h3>Choose your envelope color</h3>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <SketchPicker color={ envelopeColor} onChangeComplete={ handleChangeComplete }/>
            </div>
            <div className="form-group col-md">
              <EnvelopeSideBySide groomName={groomName} brideName={brideName} envelopeColor={envelopeColor}/>
            </div>
          </div>
          <RegistryLinks handleRegistryLinkChange = {handleRegistryLinkChange}/>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Contact Information
          </h2>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-7">
              <InputLabel style={{ textAlign: "left" }}>Email Address <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="email" id="email" variant="outlined" style={{ width: "100%" }} placeholder="info@NeedForGeeks.com" />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Phone Number <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="tel" id="number" variant="outlined" style={{ width: "100%" }} placeholder="1 (347) 280-3596" />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Pricing
          </h2>
          <div className="form-row">
            <div id="baseFee" className="pricing col-md" style={{backgroundColor: "#E6E6E6"}}>
              <p style={{fontSize:"16px"}}><b>Base Fee</b></p>
              <p className="card-text"><strike> $300</strike> <span style={{color:"red"}}> $250</span></p>
              <p className="card-text">Invite as many as you want, for one great price!</p>
            </div>
            <div id="CustomDesignFee" className="pricing col-md">
              <label htmlFor="customDesign">
                <p style={{fontSize:"16px"}}><b>Custom Design</b></p>
                <p className="card-text"><strike> +$100</strike> <span style={{color:"red"}}> +50</span></p>
                <p className="card-text">Upload your favorite invite and we'll make it for you!</p>
                <p className="text-muted">+ 5-7 Business Days</p>
              </label>
            </div>
            <div id="CustomSongFee" className="pricing col-md">
              <label htmlFor="customSong">
                <p style={{fontSize:"16px"}}><b>Custom Song</b></p>
                <p className="card-text"><strike> +$30</strike> <span style={{color:"red"}}> FREE</span></p>
                <p className="card-text">Upload your favorite song and we'll play it for you!</p>
                <p className="text-muted">+ 1-2 Business Days</p>
              </label>
            </div>
            <div id="RSVPFee" className="pricing col-md">
              <p style={{fontSize:"16px"}}><b>Allow RSVP</b></p>
              <p className="card-text"> +$50</p>
              <p className="card-text">Allow your invitees to RSVP to your event!</p>
              <FormControlLabel style={{textAlign:"center", margin:"auto"}} control={<GreenCheckbox checked={rsvp} onChange={handleRSVPChange} name="rsvp"/>}/>
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Standard Options
          </h2>


          <SongMenuItem baseSong = {baseSong} handleBaseSongChange = {handleBaseSongChange}/>

          
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Recommendations</InputLabel>
              <TextField type="text-area" id="recommendations" multiline rows={2} variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Customization Options
          </h2>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md" style={{maxWidth: "200px"}}>
              <InputLabel htmlFor="customDesign" style={{ textAlign: "left" }}>Upload a Design
                <input accept="image/*" onChange={handleCustomDesignChange} style={{ display: 'none' }} id="customDesign" name="customDesign" type="file" multiple/>
                <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Design</Button>
              </InputLabel>
            </div>
            <div id="clearDesign" className="form-group col-md-1" style={{maxWidth: "50px", visibility: "hidden"}}>
              <InputLabel style={{ textAlign: "left" }}></InputLabel>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearDesignChoice}>
                <Delete />
              </IconButton>
            </div>
            <div className="form-group col-md-8" style={{maxWidth: "500px"}}>
              <InputLabel style={{ textAlign: "left" }}>Design Files Received</InputLabel>
              <TextField disabled type="text" id="number" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={customDesignName} />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md" style={{maxWidth: "200px"}}>
              <InputLabel htmlFor="customSong" style={{ textAlign: "left" }}>Upload a Song <span id="redSong" style={{color:"red"}}></span>
                <input accept="audio/*" onChange={handleSongChange} style={{ display: 'none' }} id="customSong" name="customSong" type="file" />
                <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<Audiotrack />}>Upload A Song</Button>
              </InputLabel>
            </div>
            <div id="clearSong" className="form-group col-md-1" style={{maxWidth: "50px", visibility: "hidden"}}>
              <InputLabel style={{ textAlign: "left" }}></InputLabel>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearSongChoice}>
                <Delete />
              </IconButton>
            </div>
            <div className="form-group col-md-8" style={{maxWidth: "500px"}}>
              <InputLabel style={{ textAlign: "left" }}>Song Received </InputLabel>
              <TextField disabled type="text" id="customSongReceived" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={songName} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md" style={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={rsvp}
                    onChange={handleRSVPChange}
                    name="rsvp"
                  />
                }
                label="Allow my invitees to RSVP"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Custom Instructions</InputLabel>
              <TextField type="text-area" id="customInstructions" multiline rows={2} variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-group">
            <Button id="save" variant="contained" style={{ backgroundColor: "#1DAEE0", color: "white", margin: "10px" }} size="large" type="submit" startIcon={<Backup />}>Submit</Button>
          </div>
        </form>
      </div>
      </LoadingOverlay>
    </div>
  );
}
