import React from "react";
import "../../assets/css/style.css";
import "./CSS/Classic.css";
import "./CSS/Medias.css";
import InvitationText from "../InvitationText";

// const axios = require('axios');
// var converter = require('number-to-words');

class Classic extends React.Component {
  constructor(props) {
    super(props);
    this.brideParents = props.reqObject.brideParents;
    this.groomParents = props.reqObject.groomParents;
    this.requestLine1 = props.reqObject.requestLine1;
    this.requestLine2 = props.reqObject.requestLine2;
    this.womanName = props.reqObject.womanName;
    this.manName = props.reqObject.manName;
    this.day = props.reqObject.day;
    this.date = props.reqObject.date;
    this.year = props.reqObject.year;
    this.time = props.reqObject.time;
    this.venue = props.reqObject.venue;
    this.venueForMaps = this.venue.split(' ').join('+');
    this.venueAddress = props.reqObject.venueAddress;
    this.cityState = props.reqObject.cityState;
    this.phone = props.reqObject.phone;
    this.footerMessage1 = props.reqObject.footerMessage1;
    this.footerMessage2 = props.reqObject.footerMessage2;
    this.endingNote = props.reqObject.endingNote;
    this.bottomLeft = props.reqObject.bottomLeft;
    this.bottomRight = props.reqObject.bottomRight;
    this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.myRef = React.createRef();
    this.changed = props.reqObject.changed;
    this.isSideBySide = props.isSideBySide;
    this.baseSong = props.reqObject.baseSong;
    this.template = props.reqObject.template;
  
  }

   isupdate = 0;
  componentWillUpdate() {
    if (this.isSideBySide) {
      
      this.brideParents = this.props.reqObject.brideParents;
      this.groomParents = this.props.reqObject.groomParents;
      this.requestLine1 = this.props.reqObject.requestLine1;
      this.requestLine2 = this.props.reqObject.requestLine2;
      this.womanName = this.props.reqObject.womanName;
      this.manName = this.props.reqObject.manName;
      this.day = this.props.reqObject.day;
      this.date = this.props.reqObject.date;
      this.year = this.props.reqObject.year;
      this.time = this.props.reqObject.time;
      this.venue = this.props.reqObject.venue;
      this.venueForMaps = this.venue.split(' ').join('+');
      this.venueAddress = this.props.reqObject.venueAddress;
      this.cityState = this.props.reqObject.cityState;
      this.phone = this.props.reqObject.phone;
      this.footerMessage1 = this.props.reqObject.footerMessage1;
      this.footerMessage2 = this.props.reqObject.footerMessage2;
      this.endingNote = this.props.reqObject.endingNote;
      this.bottomLeft = this.props.reqObject.bottomLeft;
      this.bottomRight = this.props.reqObject.bottomRight;
      this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
      this.myRef = React.createRef();
      this.changed = this.props.reqObject.changed;
      this.isSideBySide = this.props.isSideBySide;
      if(this.isupdate===0)
      {
    
      this.isupdate++;
      this.setState((prevState, props) => ({
        changed: !this.changed
      }));
    
     }
     else
      {
        this.isupdate=0;
      }
    }
  }

  render() {
    var bodyStyle = this.isSideBySide? "body-style-regular-wedding":"body-style-classic-wedding";
    return (
  
        <div id="fullCard" >

        <div  className={bodyStyle}>
          <img alt="..." src={require('../../assets/img/Other/classic.png')} className="top-center-decoration" style={{ width: "60%" }} />

          <InvitationText reqObject={this.props.reqObject} handleReqObject={this.handleReqObject} isSakura={this.isSakura}  />
          <img alt="..." src={require('../../assets/img/Other/classic.png')} className="bottom-center-decoration" style={{ width: "60%"}} />

        </div>
        </div>

    );
  }
}

export default Classic;