import React from "react";
import { EmojiObjectsOutlined, PublicOutlined, MarkunreadMailboxOutlined, } from "@material-ui/icons";


const DesignTab = () => {
  return (
    <div className='row p-3'>
        <ul className="nav nav-tabs tabs-padding" id="designTab" role="tablist">
          <div className="row">
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<EmojiObjectsOutlined />}</div>
              </div>
              <div className="row">
                <p>Our users are no longer limited to choosing from template design options.</p>
              </div>
            </div>
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<PublicOutlined />}</div>
              </div>
              <div className="row">
                <p>Any user can upload a design to the system and take advantage of our guest list management, sending, and RSVP tracking features.</p>
              </div>
            </div>
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<MarkunreadMailboxOutlined />}</div>
              </div>
              <div className="row">
                <p>Every design will be delivered using our electronic envelope, which can be customized to your needs.</p>
              </div>
            </div>
          </div>
        </ul>
    </div>
  );
};

export default DesignTab;
