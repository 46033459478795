import React from "react";
import "../assets/css/style.css";
import "./ShowerTemplates/CSS/Medias.css";
import domtoimage from 'dom-to-image';
import $ from 'jquery';

var downloadName="invitation.jpeg";

class InvitationText extends React.Component {
  constructor(props) {
    super(props);
    console.log("in constructor sidebyside" + props.reqObject.womanName)
    this.sakura = props.isSakura;
    this.requestLine1 = props.reqObject.requestLine1;
    this.requestLine2 = props.reqObject.requestLine2;
    this.womanName = props.reqObject.womanName;
    this.manName = props.reqObject.manName;
    this.day = props.reqObject.day;
    this.date = props.reqObject.date;
    this.year = props.reqObject.year;
    this.time = props.reqObject.time;
    this.venue = props.reqObject.venue;
    this.venueForMaps = this.venue.split(' ').join('+');
    this.venueAddress = props.reqObject.venueAddress;
    this.cityState = props.reqObject.cityState;
    this.phone = props.reqObject.phone;
    this.footerMessage1 = props.reqObject.footerMessage1;
    this.footerMessage2 = props.reqObject.footerMessage2;
    this.endingNote = props.reqObject.endingNote;
    this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.myRef = React.createRef();
    this.changed = props.reqObject.changed;
    this.baseSong = props.reqObject.baseSong;
    this.isSideBySide = props.reqObject.isSideBySide;
    this.reqObject=props.reqObject;
    console.log(props.reqObject.template)
    this.template = props.reqObject.template;


  }

   isupdate = 0;
  componentWillUpdate() {
    console.log("in sakura sidebyside" + this.reqObject.isSideBySide)
    console.log("in sakura sidebyside" + this.props.reqObject.template)
    if (this.reqObject.isSideBySide) {
      this.requestLine1 = this.props.reqObject.requestLine1;
      this.requestLine2 = this.props.reqObject.requestLine2;
      this.womanName = this.props.reqObject.womanName.capitalize();
      this.manName = this.props.reqObject.manName.capitalize();
      this.day = this.props.reqObject.day;
      this.date = this.props.reqObject.date;
      this.year = this.props.reqObject.year;
      this.time = this.props.reqObject.time;
      this.venue = this.props.reqObject.venue;
      this.venueForMaps = this.venue.split(' ').join('+');
      this.venueAddress = this.props.reqObject.venueAddress;
      this.cityState = this.props.reqObject.cityState;
      this.phone = this.props.reqObject.phone;
      this.footerMessage1 = this.props.reqObject.footerMessage1;
      this.footerMessage2 = this.props.reqObject.footerMessage2;
      this.endingNote = this.props.reqObject.endingNote;
      this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
      this.myRef = React.createRef();
      this.changed = this.props.reqObject.changed;
      this.isSideBySide = this.props.reqObject.isSideBySide;
      this.reqObject =
    {
      isSideBySide:true,
      loadingActive:false,
      customDesign:'',
      template: this.template,
      requestLine1: this.props.reqObject.requestLine1,
      requestLine2: this.props.reqObject.requestLine2,
      womanName: this.props.reqObject.womanName,
      manName: this.props.reqObject.manName,
      day: this.props.reqObject.day,
      date: this.props.reqObject.date,
      year: this.props.reqObject.year,
      time: this.props.reqObject.time,
      brideInitial : this.props.reqObject.womanName.charAt(0).capitalize(),
      groomInitial :  this.props.reqObject.manName.charAt(0).capitalize(),
      venue: this.props.reqObject.venue,
      venueForMaps: this.props.reqObject.venueForMaps,
      venueAddress: this.props.reqObject.venueAddress,
      cityState: this.props.reqObject.cityState,
      phone: this.props.reqObject.phone,
      link: this.props.reqObject.link,
      footerMessage1: this.props.reqObject.footerMessage1,
      footerMessage2: this.props.reqObject.footerMessage2,
      endingNote: this.props.reqObject.endingNote,
      changed: this.props.reqObject.changed
    }
      if (this.isupdate === 0) {
        this.isupdate++;
      
        this.setState((prevState, props) => ({
          changed: !this.props.reqObject.changed
        }));
        console.log('supppp' + this.changed)
      }
      else {
        this.isupdate = 0;
      }
    }
  }
 
  DownloadPdf() {
    if(this.reqObject.isSideBySide)
    {
      window.alert("Downloading Document will be available once payment has been made.")
    }
    else
    {
    window.scrollTo(0, 0)
    
    document.getElementById("download-button").style.display = "none";
    document.getElementById("directions-button").style.display = "none";
    var node = document.getElementById('fullInvite');
    
    if(this.reqObject.manName !== ""){
      downloadName=this.reqObject.womanName + '_and_' + this.reqObject.manName + `'s_Bridal_Shower.jpeg`;
    }
    else {
      downloadName=this.reqObject.womanName + `'s_Bridal_Shower.jpeg`;
    }

    domtoimage.toJpeg(node,{ quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = downloadName;
        link.href = dataUrl;
        link.click();
      }).then(function() {
        document.getElementById("download-button").style.display = "block";
        document.getElementById("directions-button").style.display = "block";
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      }).then(function() {
        document.getElementById("download-button").style.display = "block";
        document.getElementById("directions-button").style.display = "block";
      });
    }
  }

  componentDidMount()
  {
    if(window.screen.width > 600)
    {
      this.zoomSquare() 
      window.addEventListener('resize', this.zoomSquare)
    }
  }
  
   zoomSquare() {
    
    var $square = $('#fullCard');

    var viewportHeight = $(window).height();
    var squareHeight = $square.height();
    var desiredHeight = Math.round(viewportHeight * 0.95);
    var zoom = (desiredHeight / squareHeight);

    $square.css('zoom', zoom);
    $square.css('-moz-transform', 'scale(' + zoom + ')');
    $square.css(  '-o-transform', 'scale(' + zoom + ')');
}

  render() {
    var buttonsCss = "venue-shower " + this.template+"-venue-shower"
    var buttonDownload = buttonsCss + " downloadButton-shower"
    var womanNameCss = this.template+"-woman-name-shower";
    var headerStyle = this.template+"-header-style-shower";
    var titleCss = this.template+"-title-shower title-shower";
    var invitationFooter1 = this.template+"-invitation-footer-shower invitation-footer1-shower";
    var invitationFooter2 = this.template+"-invitation-footer-shower invitation-footer2-shower";
    var timeVenue = this.template+"-dance-sm-shower dance-sm-shower";
    var happiness = this.template+"-happiness-shower happiness-shower";
    var andSignCss = this.manName === "" ? this.template+"-and-sign-shower display-none" : this.template+"-and-sign-shower" ;
    var manNameCss = this.manName === "" ? this.template+"-man-name-shower display-none" : this.template+"-man-name-shower" ;

    return (
      <div id = "defaulText" >
        <section id="media" />
        <div className="wrap">
          <div className={titleCss}>
            <p className = {headerStyle}>{this.requestLine1}</p>
            <p className = {headerStyle}>{this.requestLine2}</p>
            <h1 className = {womanNameCss}>{this.womanName}</h1>
            <h2 className ={andSignCss} id="andSign">&amp;</h2>
            <h1 className ={manNameCss} id="manName">{this.manName}</h1>
          </div>
        </div>
        <p className={timeVenue}>{this.day}, {this.date}</p>
        <p className={timeVenue}>{this.year}</p>
        <p className={timeVenue}>At {this.time}</p>
        <p className={timeVenue}>{this.venue}</p>
        <p className={timeVenue}>{this.venueAddress}</p>
        <p className={timeVenue}>{this.cityState}</p>
        <div className="actions">
          <a href={this.link} target="_blank" rel="noopener noreferrer" id="directions-button">
            <div className={buttonsCss} ><b>DIRECTIONS</b></div>
          </a>
          <a href={() => false} onClick={() => this.DownloadPdf()} id="download-button">
            <div className={buttonDownload}><b>DOWNLOAD</b></div>
          </a>
        </div>
        <p className={invitationFooter1}>{this.footerMessage1}</p>
        <p className={invitationFooter2}>{this.footerMessage2}</p>
        <div>
          {this.props.reqObject.isSideBySide ?
            null :
            <audio className="audio-element" src={this.baseSong}>
            </audio>
          }
        </div>
        <p className={happiness}>{this.endingNote}<br/></p>
      </div>
    );
  }
}

export default InvitationText;