import React from "react";
import "../../assets/css/style.css";
import "./CSS/Wreath.css";
import "./CSS/Medias.css";
import InvitationText from "../InvitationText";

class Wreath extends React.Component {
  constructor(props) {
    super(props);
    this.brideParents = props.reqObject.brideParents;
    this.groomParents = props.reqObject.groomParents;
    this.requestLine1 = props.reqObject.requestLine1;
    this.requestLine2 = props.reqObject.requestLine2;
    this.womanName = props.reqObject.womanName;
    this.manName = props.reqObject.manName;
    this.day = props.reqObject.day;
    this.date = props.reqObject.date;
    this.year = props.reqObject.year;
    this.brideInitial = props.reqObject.brideInitial;
    this.groomInitial = props.reqObject.groomInitial;
    this.time = props.reqObject.time;
    this.venue = props.reqObject.venue;
    this.venueForMaps = this.venue.split(' ').join('+');
    this.venueAddress = props.reqObject.venueAddress;
    this.cityState = props.reqObject.cityState;
    this.phone = props.reqObject.phone;
    this.footerMessage1 = props.reqObject.footerMessage1;
    this.footerMessage2 = props.reqObject.footerMessage2;
    this.endingNote = props.reqObject.endingNote;
    this.bottomLeft = props.reqObject.bottomLeft;
    this.bottomRight = props.reqObject.bottomRight;
    this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.myRef = React.createRef();
    this.changed = props.reqObject.changed;
    this.isSideBySide = props.isSideBySide;
    this.baseSong = props.reqObject.baseSong;
    
  }
  getHours(date) {
    return date.getHours() % 12  || 12;
  }
  
  isupdate = 0;
  componentWillUpdate() {
    if (this.isSideBySide) {
      this.brideParents = this.props.reqObject.brideParents;
      this.groomParents = this.props.reqObject.groomParents;
      this.requestLine1 = this.props.reqObject.requestLine1;
      this.requestLine2 = this.props.reqObject.requestLine2;
      this.womanName = this.props.reqObject.womanName.capitalize();
      this.manName = this.props.reqObject.manName.capitalize();
      this.day = this.props.reqObject.day;
      this.date = this.props.reqObject.date;
      this.year = this.props.reqObject.year;
      this.time = this.props.reqObject.time;
      this.venue = this.props.reqObject.venue;
      this.venueForMaps = this.venue.split(' ').join('+');
      this.venueAddress = this.props.reqObject.venueAddress;
      this.cityState = this.props.reqObject.cityState;
      this.phone = this.props.reqObject.phone;
      this.footerMessage1 = this.props.reqObject.footerMessage1;
      this.footerMessage2 = this.props.reqObject.footerMessage2;
      this.endingNote = this.props.reqObject.endingNote;
      this.bottomLeft = this.props.reqObject.bottomLeft;
      this.bottomRight = this.props.reqObject.bottomRight;
      this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
      this.myRef = React.createRef();
      this.changed = this.props.reqObject.changed;
      this.brideInitial = this.props.reqObject.womanName.charAt(0).capitalize();
        this.groomInitial =  this.props.reqObject.manName.charAt(0).capitalize();
      this.isSideBySide = this.props.isSideBySide;
     if(this.isupdate===0)
     {
      this.setState((prevState, props) => ({
        changed: !this.changed
      }));
      this.isupdate++;
     }
     else
      {
        this.isupdate=0;
      }
    }
  }

  render() {
    var bodyStyle = this.isSideBySide? "body-style-regular-wedding":"body-style-wreath-wedding";
    return (
        <div id="fullCard" style={{margin:"auto"}} className={bodyStyle} >
          <div >
            <div className="wreath-wedding">
              <h1 className="centerInitial-wedding"  >{this.brideInitial} | {this.groomInitial}</h1>
            </div>
            <div style={{ marginTop: "120px"}}>
            <InvitationText reqObject={this.props.reqObject} handleReqObject={this.handleReqObject} isSakura={this.isSakura}  />


            </div>
            
         
        </div>
    </div>

    );
  }
}

export default Wreath;