import React from "react";
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

export default function Overview() {
    return (
    <div> 
      <div id="overview" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px"}}>
        <div className="container container-fluid p-5">
          <h1 className="display-4-overview mb-2 text-center"><b>Effortless Online Invitations and RSVP Tracking</b></h1>
          <br/>
          <p className="lead text-center" style={{fontSize: ".9rem"}}>Deliver elegant online invitations directly to all of your friends and family with NeedForGeeks. Whether you want to upload your own custom design or tailor one of our exclusive designer suites, every invitation is delivered complete with personalized digital envelope, liner, stamp and RSVP tracking.</p>
          <div className="tab-content gallery mt-5">
            <div className="tab-pane active">
              <div className="ml-auto mr-auto">
                <div className="row justify-content-center">
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                      <Link to="/choose-wedding-template">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Wedding.png')} alt="" />
                            <figcaption>
                              <div className="h4">Wedding</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/choose-save-date-template">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/SaveDate.png')} alt="" />
                            <figcaption>
                              <div className="h4">Save The Date</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/choose-shower-template">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/BridalShower.png')} alt="" />
                            <figcaption>
                              <div className="h4">Bridal Shower</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/choose-baby-template">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Baby.png')} alt="" />
                            <figcaption>
                              <div className="h4">Baby</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/SocialDistancing.png')} alt="" />
                            <figcaption>
                              <div className="h4">Social Distancing</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Birthday.png')} alt="" />
                            <figcaption>
                              <div className="h4">Birthday</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Virtual.png')} alt="" />
                            <figcaption>
                              <div className="h4">Virtual Gatherings</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Cancel_Postpone.png')} alt="" />
                            <figcaption>
                              <div className="h4">Cancel/ Postpone</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Moving.png')} alt="" />
                            <figcaption>
                              <div className="h4">Moving</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-2_5" style={{width:"150px", height:"150px"}}>
                    <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                      <Button style={{ padding: "5px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span">
                        <Link to="/comingsoon">
                          <figure className="cc-effect" style={{ marginBottom: 0 }}><img src={require('./Logos/Other.png')} alt="" />
                            <figcaption>
                              <div className="h4">Other</div>
                            </figcaption>
                          </figure>
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
