import React from "react";
import "../../assets/css/style.css";
import domtoimage from 'dom-to-image';
var downloadName="invitation.jpeg";

class CustomInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.customDesign = props.reqObject.customDesign;
    this.venue = props.reqObject.venue;
    this.venueForMaps = this.venue.split(' ').join('+');
    this.venueAddress = props.reqObject.venueAddress;
    this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.changed = props.reqObject.changed;
    this.isSideBySide = props.isSideBySide;
    this.baseSong = props.reqObject.baseSong;
    
  }
 
  
  isupdate = 0;
  componentWillUpdate() {
    if (this.isSideBySide) {
      this.venue = this.props.reqObject.venue;
      this.venueForMaps = this.venue.split(' ').join('+');
      this.venueAddress = this.props.reqObject.venueAddress;
      this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.customDesign = this.props.reqObject.customDesign;
      this.changed = this.props.reqObject.changed;
      this.isSideBySide = this.props.isSideBySide;
     if(this.isupdate===0)
     {
      this.setState((prevState, props) => ({
        changed: !this.changed
      }));
      this.isupdate++;
     }
     else
      {
        this.isupdate=0;
      }
    }
  }

  DownloadPdf() {
    if(this.props.isSideBySide)
    {
      window.alert("Downloading Document will be available once payment has been made.")
    }
    else
    {
    window.scrollTo(0, 0)
    document.getElementById("download-button").style.display = "none";
    document.getElementById("directions-button").style.display = "none";
    var node = document.getElementById('fullInvite');
    
    downloadName= this.props.womanName + '_and_' + this.props.manName + `'s_Save_Date.jpeg`;

    domtoimage.toJpeg(node,{ quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = downloadName;
        link.href = dataUrl;
        link.click();
      }).then(function() {
        document.getElementById("download-button").style.display = "block";
        document.getElementById("directions-button").style.display = "block";
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      }).then(function() {
        document.getElementById("download-button").style.display = "block";    
        document.getElementById("directions-button").style.display = "block";
      });
    }
  }

  render() {
    var bodyStyle = this.isSideBySide? "body-style-regular-shower":"body-style-custom-shower";
    return (
      <div id="fullCard" style={{margin:"auto"}} className={bodyStyle} >
        <img alt='customImage' id='customImage' src = {this.customDesign}/>
        <div className='row' style={{margin:0,alignItems:"center",justifyContent:"center"}}>
          <a href={this.link} target="_blank" rel="noopener noreferrer" id="directions-button">
            <div className="venue-shower" style={{ backgroundColor: "#1DAEE0" }}><b>DIRECTIONS</b></div>
          </a>
          <a href={() => false} onClick={() => this.DownloadPdf()} style={{ cursor: 'pointer' }} id="download-button">
            <div className="venue-shower downloadButton-shower" style={{ backgroundColor: "#1DAEE0" }} ><b>DOWNLOAD</b></div>
          </a>
        </div>
        <div>
          {this.isSideBySide ?
            null :
            <audio className="audio-element"  src={this.baseSong}>
            </audio>
          }
        </div>
      </div>
    );
  }
}

export default CustomInvitation;