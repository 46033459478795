import React from "react";
import "../../assets/css/style.css";
import "./CSS/SilverGlitter.css";
import "./CSS/Medias.css";
import InvitationText from "../InvitationText";

class SilverGlitter extends React.Component {
  constructor(props) {
    super(props);
    console.log('fuck2' + props.reqObject.womanName )
    this.sakura = props.isSakura;
    this.requestLine1 = props.reqObject.requestLine1;
    this.requestLine2 = props.reqObject.requestLine2;
    this.womanName = props.reqObject.womanName;
    this.manName = props.reqObject.manName;
    this.day = props.reqObject.day;
    this.date = props.reqObject.date;
    this.year = props.reqObject.year;
    this.time = props.reqObject.time;
    this.venue = props.reqObject.venue;
    this.venueForMaps = this.venue.split(' ').join('+');
    this.venueAddress = props.reqObject.venueAddress;
    this.cityState = props.reqObject.cityState;
    this.phone = props.reqObject.phone;
    this.footerMessage1 = props.reqObject.footerMessage1;
    this.footerMessage2 = props.reqObject.footerMessage2;
    this.endingNote = props.reqObject.endingNote;
    this.link = `https://www.google.com/maps/search/?api=1&query=${this.venueForMaps}`;
    this.myRef = React.createRef();
    this.changed = props.reqObject.changed;
    this.baseSong = props.reqObject.baseSong;
    this.isSideBySide = props.isSideBySide;
    this.reqObject=props.reqObject;
    this.handleReqObject=props.handleReqObject;
  }

  render() {
    var bodyStyle = this.isSideBySide? "body-style-regular-baby":"body-style-silverGlitter-baby";
   
    return (
      <div id="fullCard" className={bodyStyle}>
        <div >
          <img alt="..." src={require('../../assets/img/SilverGlitter/top.png')} className="top-right-decoration-silverGlitter"/>
          <InvitationText reqObject={this.props.reqObject} handleReqObject={this.handleReqObject} isSakura={this.isSakura}  />
        </div>
        <img alt="..." src={require('../../assets/img/SilverGlitter/bottom.png')} className="bottom-left-decoration-silverGlitter"/>
      </div>
    );
  }
}

export default SilverGlitter;