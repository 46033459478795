import React from "react";
import { useHistory } from "react-router-dom";
import { Button, TextField, InputLabel, IconButton } from "@material-ui/core";
import {  Backup, PhotoCamera, Audiotrack, Delete, Add } from "@material-ui/icons";
import LoadingOverlay from "react-loading-overlay";
import SongMenuItem from "../assets/resources/SongMenuItem";
import RegistryLinks from "../assets/resources/registryLinks";
import EnvelopeSideBySide from "../assets/resources/EnvelopeSideBySide";
import { SketchPicker } from 'react-color';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


export default function CustomForm(props) {
  const [photoName, setPhotoName] = React.useState('');
  const [eventDate, setEventDate] = React.useState(new Date());

  const [registryLinks, setRegistryLinks] = React.useState("");
  const [envelopeColor, setEnvelopeColor] = React.useState("black");
  const [groomName] = React.useState("Romeo");
  const [brideName] = React.useState("Juliet");
  const [customDesignName, setCustomDesignName] = React.useState(null);
  const [baseSong, setBaseSong] = React.useState('');
  const [songName, setSongName] = React.useState(null);
  const [loadingActive, setLoadingActive] = React.useState(false);
  const [balance, setBalance] = React.useState(250);
  const [local, setLocal] = React.useState(false);

  const history = useHistory();

  const handleRegistryLinkChange = (list) => {
    console.log("list is ")
    console.log(list)
   var map =  list.map( (a, i) => `${a.registryLabel}|${a.registryURL}`).join(',');
    setRegistryLinks(map);
  };
  const handleGroomDisplayClick = () => {
    document.getElementById("groomDisplayButton").style.display = "none";
    document.getElementById("groomNameDiv").style.display = "block";
    document.getElementById("groomDontDisplayButton").style.display = "block";
  };
  const handleEventDateChange = (date) => {
    setEventDate(date);
  };
  const handleChangeComplete = (color) => {
    setEnvelopeColor(color.hex);
     document.documentElement.style.setProperty("--base", color.hex);
     document.documentElement.style.setProperty("--base-dark", color.hex);
     document.documentElement.style.setProperty("--base-light", color.hex);
     document.documentElement.style.setProperty("--letter", color.hex);
   };

  const handleGroomDontDisplayClick = () => {
    document.getElementById("groomDisplayButton").style.display = "block";
    document.getElementById("groomNameDiv").style.display = "none";
    document.getElementById("groomDontDisplayButton").style.display = "none";
    document.getElementById("groomName").value = "";
  };

  const handlePhotoChange = (event) => {
    if (event.target.files[0]) {
      setPhotoName(event.target.files[0].name);
      document.getElementById("clearProof").style.visibility = "visible";
    }
  };

  const clearPaymentChoice = (event) => {
    setPhotoName('');
    document.getElementById("clearProof").style.visibility = "hidden";
  }
  var temp = props.template;
  const handleCustomDesignChange = (event) => {
    if (event.target.files[0]) {
      if (temp === ('custom')) {
        document.getElementById('uploadImageText').style.display = 'none';
        document.getElementById('customImage').src = window.URL.createObjectURL(event.target.files[0])
        window.scroll(0, 0)
      }
    
      setCustomDesignName(event.target.files[0].name);
      document.getElementById("clearDesign").style.visibility = "visible";
      console.log(event.target.files[0].filepath + 'uck')
      if (event.target.files[0].name !== '') {
        setBalance(balance + 50);
      }
      else {
        setBalance(balance - 50);
      }
    }
  };

  const clearDesignChoice = (event) => {
    setCustomDesignName('');
    document.getElementById("clearDesign").style.visibility = "hidden";
    if (temp === ('custom')) {
      document.getElementById('uploadImageText').style.display = 'visible';
      document.getElementById('customImage').style.display = 'none';
      document.getElementById('customImage').src = '';
      window.scroll(0, 0)
    }
    setBalance(balance - 50);
  }

  const handleSongChange = (event) => {
    if (event.target.files[0]) {
     
      setSongName(event.target.files[0].name);
      document.getElementById("clearSong").style.visibility = "visible";
    
      if (event.target.files[0].name !== '') {
        setBalance(balance + 0);
      }
      else {
        setBalance(balance - 0);
      }
    }
  };

  const clearSongChoice = (event) => {
    setSongName('');
    document.getElementById("clearSong").style.visibility = "hidden";
    // setBalance(balance - 30);
    setBalance(balance - 0);
  }


  const handleBaseSongChange = (event) => {
    setBaseSong(event.target.value);
    if (event.target.value === "Custom") {
      document.getElementById("customSongReceived").setAttribute("required", "");
      document.getElementById("red").innerHTML = "*";
    }
    else
      document.getElementById("customSongReceived").removeAttribute("required");
    document.getElementById("red").innerHTML = "";
  };

  
  const handleSubmit = e => {
    e.preventDefault();
    
    var imagefile = document.querySelector('#proofOfPayment');
    var songfile = document.querySelector('#customSong');
    var designFile = document.querySelector('#customDesign');
    if (imagefile.files.length === 0) {
      window.alert("Proof of Payment must be uploaded")
    
    }
    else if (baseSong === 'Custom' && songfile.files.length === 0) {
      window.alert('A custom song must be uploaded otherwise please choose a song from the dropdown list')
    
    }
    else if (props.template === 'custom' && designFile.files.length === 0) {
    
      window.alert('A custom design must be uploaded otherwise please choose another template from the homepage menu')
    }
    else {
      setLoadingActive(true);
      const venueName = document.getElementById("venueName").value;
      const email = document.getElementById("email").value;
      const number = document.getElementById("number").value;
      const brideName = document.getElementById("brideName").value;
      const groomName = document.getElementById("groomName").value;
      const recommendation = document.getElementById("recommendations").value;
      const customInstructions = document.getElementById("customInstructions").value;

      var paramObject = {
        "registryLinks": registryLinks,
        "invitationType": props.reqObject.invitationType,
        "envelopeColor": envelopeColor,
        "customSong": songfile.files[0],
        "customDesign": designFile.files[0],
        "customInstructions": customInstructions,
        "recommendation": recommendation,
        "baseSong": baseSong,
        "brideName": brideName,
        "groomName": groomName,
        "venueName": venueName,
        "email": email,
        "number": number,
        "template": props.template,
        "balance": balance,
        "eventDate":eventDate,
        "isActive":false
      }
      
      history.push({
        pathname: '/make-payment',
        state: paramObject,
      });
    }

  };

  return (
    <div
      id="custom-form-page"
      className="bg-transparent min-vh-100"
      style={{ border: "2px solid grey", borderRadius: "8px", margin: "10px" }}
      width="100%"
    >
       <LoadingOverlay active={loadingActive} spinner text="Loading your content...">
       
      <div className="container container-fluid text-center">
        <form
          autoCorrect="off"
          onSubmit={e => handleSubmit(e)}
          id="custom-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
            paddingTop: "15px",
            marginTop: 0
          }}
          >
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
            id = 'uploadImageText' 
          >
            Upload an Image to view Invitation
          </h2>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Bride's Name <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="brideName" style={{ marginTop: 0, width: "100%" }} defaultValue="Juliet" />
            </div>
            <div className="form-group col-md" id="groomNameDiv" style={{ display:"none" }}>
              <InputLabel style={{ textAlign: "left" }}>Groom's Name <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="groomName" variant="outlined" style={{ marginTop: 0, width: "100%"}} defaultValue="" />
            </div>
            <div className="form-group col-md" id="groomDisplayButton">
              <InputLabel style={{ textAlign: "left" }}>Groom's Name</InputLabel>
              <Button variant="outlined" style={{color: "#1DAEE0", borderColor: "#1DAEE0", width: "100%", height:"56px"}} onClick={handleGroomDisplayClick} startIcon={<Add />}>Add Groom's Name</Button>
            </div>
            <div className="form-group col-md-1"  id="groomDontDisplayButton" style={{maxWidth: "50px", display: "none"}}>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={handleGroomDontDisplayClick}>
                <Delete />
              </IconButton>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Name of Venue <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="venueName" variant="outlined" style={{ width: "100%" }} placeholder="Kol Yaakov" />
            </div>
            {/* <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Venue Address <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="venueAddress" variant="outlined" style={{ width: "100%" }} placeholder="1703 McDonald Avenue" />
            </div> */}
          </div>
          <div className="form-group col-md-5">
              <InputLabel style={{ textAlign: "left" }}>Date/Time of Event <span style={{ color: "red" }}>*</span></InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  color="#1DAEE0"
                  inputVariant="outlined"
                  value={eventDate}
                  ampm={true}
                  onChange={handleEventDateChange}
                  id="eventDate"
                  disablePast
                  style={{ width: "100%" }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    'background-color': '#1DAEE0',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md" style={{ maxWidth: "200px" }}>
              <InputLabel style={{ textAlign: "left" }}>Upload a Design <span style={{ color: "red" }}>*</span>
                <input accept="image/*" onChange={handleCustomDesignChange} style={{ display: 'none' }} id="customDesign" name="customDesign" type="file"  />
                <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Design</Button>
              </InputLabel>
            </div>
            <div id="clearDesign" className="form-group col-md-1" style={{ maxWidth: "50px", visibility: "hidden" }}>
              <InputLabel style={{ textAlign: "left" }}></InputLabel>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearDesignChoice}>
                <Delete />
              </IconButton>
            </div>
            <div className="form-group col-md-8" style={{ maxWidth: "500px" }}>
              <InputLabel style={{ textAlign: "left" }}>Design Files Received</InputLabel>
              <TextField disabled type="text" id="number" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={customDesignName} />
            </div>
          </div>
          <div className="form-row ">
            <h3>Choose your envelope color</h3>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <SketchPicker color={ envelopeColor} onChangeComplete={ handleChangeComplete }/>
            </div>
            <div className="form-group col-md">
              <EnvelopeSideBySide groomName={groomName} brideName={brideName} envelopeColor={envelopeColor}/>
            </div>
          </div>
          <RegistryLinks handleRegistryLinkChange = {handleRegistryLinkChange}/>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Contact Information
          </h2>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-7">
              <InputLabel style={{ textAlign: "left" }}>Email Address <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="email" id="email" variant="outlined" style={{ width: "100%" }} placeholder="info@NeedForGeeks.com" />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Phone Number <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="tel" id="number" variant="outlined" style={{ width: "100%" }} placeholder="1 (347) 280-3596" />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Pricing
          </h2>
          <div className="form-row">
            <div className="pricing col-md">
              <p style={{ fontSize: "16px" }}><b>Base Fee</b></p>
              <p className="card-text"><strike> $300</strike> <span style={{ color: "red" }}> $250</span></p>
              <p className="card-text">Invite as many as you want, for one great price!</p>
            </div>
            <div className="pricing col-md">
              <p style={{ fontSize: "16px" }}><b>Custom Song</b></p>
              <p className="card-text"><strike> +$30</strike> <span style={{ color: "red" }}> FREE</span></p>
              <p className="card-text">Upload your favorite song and we'll play it for you!</p>
              <p className="text-muted">+ 1-2 Business Days</p>
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Standard Options
          </h2>
         
          <SongMenuItem baseSong = {baseSong} handleBaseSongChange = {handleBaseSongChange}/>


          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Recommendations</InputLabel>
              <TextField type="text-area" id="recommendations" multiline rows={2} variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Customization Options
          </h2>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md" style={{ maxWidth: "200px" }}>
              <InputLabel htmlFor="customSong" style={{ textAlign: "left" }}>Upload a Song <span id="red" style={{ color: "red" }}></span>
                <input accept="audio/*" onChange={handleSongChange} style={{ display: 'none' }} id="customSong" name="customSong" type="file" />
                <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<Audiotrack />}>Upload A Song</Button>
              </InputLabel>
            </div>
            <div id="clearSong" className="form-group col-md-1" style={{ maxWidth: "50px", visibility: "hidden" }}>
              <InputLabel style={{ textAlign: "left" }}></InputLabel>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearSongChoice}>
                <Delete />
              </IconButton>
            </div>
            <div className="form-group col-md-8" style={{ maxWidth: "500px" }}>
              <InputLabel style={{ textAlign: "left" }}>Song Received </InputLabel>
              <TextField disabled type="text" id="customSongReceived" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={songName} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Custom Instructions</InputLabel>
              <TextField type="text-area" id="customInstructions" multiline rows={2} variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <h2 display="block" align="left"
                style={{
                  paddingTop: "20px",
                  margin: "0rem",
                  paddingBottom: "20px",
                  fontSize: "1.5rem",
                }}
              >
                Payment Information
              </h2>
            </div>
            <div className="form-group col-md">
              <h2 display="block" align="right"
                style={{
                  paddingTop: "20px",
                  margin: "0rem",
                  paddingBottom: "20px",
                  fontSize: "1rem",
                  color: "#173962"
                }}
              >
                Total balance = <span style={{ color: "red" }}>${balance}</span>
              </h2>
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md" style={{ maxWidth: "200px" }}>
              <InputLabel htmlFor="proofOfPayment" style={{ textAlign: "left" }} >Proof of Payment <span style={{ color: "red" }}>*</span>
                <input accept="image/*" onChange={handlePhotoChange} style={{ display: 'none' }} id="proofOfPayment" name="proofOfPayment" type="file" />
                <Button style={{ height: "56px", marginTop: "8px", color: "#1DAEE0", borderColor: "#1DAEE0" }} variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Photo</Button>
              </InputLabel>
            </div>
            <div id="clearProof" className="form-group col-md-1" style={{ maxWidth: "50px", visibility: "hidden" }}>
              <InputLabel style={{ textAlign: "left" }}></InputLabel>
              <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={clearPaymentChoice}>
                <Delete />
              </IconButton>
            </div>
            <div className="form-group col-md-8" style={{ maxWidth: "500px" }}>
              <InputLabel style={{ textAlign: "left" }}>Proof of Payment Received</InputLabel>
              <TextField disabled required type="text" id="number" variant="outlined" style={{ backgroundColor: "#D3D3D3", width: "100%" }} value={photoName} />
            </div>
          </div>
          <h2 display="block" align="left"
            style={{
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1rem",
            }}
          >
            Checkout our <a href="/How-To-Pay" target="_blank">How To Pay</a> page to view accepted payment methods. Upload a screenshot when you're done!
          </h2>
          <div className="form-group">
            <Button id="save" variant="contained" style={{ backgroundColor: "#1DAEE0", color: "white", margin: "10px" }} size="large" type="submit" startIcon={<Backup />}>Submit</Button>
          </div>
        </form>
      </div>
      </ LoadingOverlay>
    </div>
  );
}
