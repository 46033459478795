import React from "react";

 export default class RegistryLinksPopUp extends React.Component {
    constructor(props) {
      super(props);
      this.isActive = props.isActive;
      if(props.reqObject.registryLinks!=null)
        this.myArr = props.reqObject.registryLinks.split(",");
        else
        this.myArr=null

    }

     makeHeightHigher = () =>
    {
      document.getElementById("supbro").style.height = "200px";
    }
    restoreHeight = () =>
    {
     document.getElementById("supbro").style.height = "100px";
    //  var selectBox = document.getElementById("selectList");
    //  var selectedValue = selectBox.options[selectBox.selectedIndex].value;
      // window.location.href=selectedValue;
      // window.open(selectedValue, '_blank').focus();
      // selectBox.options[selectBox.selectedIndex].value = "default";
    }

    render(){
      var count = 1;

      var rows =[];
      if(this.myArr!=null)
      {
      this.myArr.forEach(i =>
       {
        var  row = {
         id:count,
         SiteName:i.split("|")[0],
         SiteUrl:i.split("|")[1],
         };
 
         rows.push(row);
         count = count+1;
      });
    }
    // const columns =[
    //   {
    //     field: 'id',
    //     hide: true,
    //   },
    //   { field: 'SiteName', headerName: 'Site Name', width: 150 },
    //   { field: 'SiteUrl', headerName: 'Site Url', width: 200 }
    // ];

    return (
     this.myArr == null?
      null
      :
  
//     <div class="d-flex" className="btn btn-outline-light" style={{backgroundColor:"rgb(105, 136, 137)"}}>
//     <select name="selectList" id="selectList" onChange={this.restoreHeight} style={{backgroundColor:"rgb(105, 136, 137)",color:"white"}}>
//     <option value="default">Registry Links</option>
//     {this.myArr.map(link => (
//       <option value={link.split("|")[1]}>{link.split("|")[0]}</option>
//      ))}
//   
// </select>

        
//     </div>
<div className="d-flex">
  <button className="btn btn-outline-light" onClick={this.makeHeightHigher} style={{backgroundColor:"#1DAEE0"}} type="button" data-toggle="dropdown">Registry Links<span class="caret"></span></button>
  <ul className="dropdown-menu">
    {this.myArr.map(link => (
      <li><a onClick={this.restoreHeight} href={link.split("|")[1]} target="_blank" rel="noopener noreferrer">{link.split("|")[0]}</a></li>
    ))}
  </ul>
</div>
    );
    }

}