import React from "react";
import Classic from "./WeddingTemplates/Classic";
import Wreath from "./WeddingTemplates/Wreath";
import FloralFrame from "./WeddingTemplates/FloralFrame";
import Sakura from "./WeddingTemplates/Sakura";
import GoldDots from "./WeddingTemplates/GoldDots";
import GoldGlitter from "./WeddingTemplates/GoldGlitter";
import MarsalaFloral from "./WeddingTemplates/MarsalaFloral";
import SilverGlitter from "./WeddingTemplates/SilverGlitter";
import "./WeddingTemplates/CSS/Medias.css";
import '../assets/css/envelopeCss.scss';
import texturedBackground from "../assets/img/Other/texturedBackground.png";
import silverBackground from "../assets/img/SilverGlitter/background.png";
import CustomInvitation from "./WeddingTemplates/CustomInvitation";
import EnvelopeLinks from "../assets/resources/EnvelopeLinks";
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";
import { doc, getDoc } from "firebase/firestore";

import {db} from '../../../firebase';

var converter = require('number-to-words');

const axios = require('axios');
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class Envelope extends React.Component {

  constructor() {
    super();

    this.reqObject =
    {
      template: '',
      brideParents: '',
      groomParents: '',
      requestLine1: '',
      requestLine2: '',
      womanName: '',
      manName: '',
      day: '',
      date: '',
      year: '',
      time: '',
      venue: '',
      venueForMaps: '',
      registryLinks:'',
      venueAddress: '',
      cityState: '',
      phone: '',
      link: '',
      footerMessage1: '',
      footerMessage2: '',
      endingNote: '',
      bottomLeft: '',
      bottomRight: '',
      changed: false,
      brideInitial:'',
      groomInitial :'',
      customDesign:'',
      isEnvelopeOpen: false,
      allowRsvp : 'false',
      eventDate:'',
      isActive:false
    }
    
  
  }
  state = {
    reqObj: this.reqObject
  };
  
  getHours(date) {
    return date.getHours() % 12 || 12;
  }
 
  async componentWillMount() {
    var urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('documentId');
    var there = this;
    var ss = "Invitations";
    if(window.location.href.includes('localhost'))
    {
      
    }
    console.log(ss)
    const docRef = doc(db, ss, myParam);    
    const response =  await getDoc(docRef);
    console.log(response.exists)
        if (response.exists) {
          console.log("Document data:", response.data());
        there.template = response.data().template
        if (there.template === "sakura") {
          there.isSakura = true;
        }
      
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var d = new Date();
      console.log(response.data().groomName)
          d = new Date(response.data().eventDate.toString());
        console.log(d)
      

        var dayName = days[d.getDay()];
        var date = converter.toOrdinal(d.getUTCDate());
        // eslint-disable-next-line no-extend-native
        String.prototype.capitalize = function () {
          return this.charAt(0).toUpperCase() + this.slice(1);
        }

        var hours = d.getHours();
        var ampm = hours >= 12 ? 'pm' : 'am';
        var timeOfDay = ampm.includes("am") ? "morning" : "evening";
        var forMap = response.data().venueName.split(' ').join('+');
        var envelopeColor  = response.data().envelopeColor ==null?"black":response.data().envelopeColor;
        there.time = converter.toWords(there.getHours(d)) + " " + converter.toWords(d.getMinutes()) + " in the " + timeOfDay;
        var newTime = there.time.replace("zero", "o'clock");
        document.documentElement.style.setProperty("--base", envelopeColor);
        document.documentElement.style.setProperty("--base-dark",envelopeColor);
        document.documentElement.style.setProperty("--base-light",envelopeColor);
        document.documentElement.style.setProperty("--letter", envelopeColor);

        if(response.data().template !== 'custom')
        {
        there.reqObject =
        {
          eventDate : response.data().eventDate.toString(),
          template: response.data().template,
          date: "the " + date + " of " + d.toLocaleString('default', { month: 'long' }),
          year: converter.toWords(d.getFullYear()).split(',').join(' and ').capitalize(),
          time: newTime,
          footerMessage1: response.data().footerMessage1,
          footerMessage2: response.data().footerMessage2,
          customDesign:response.data().customDesign,
          endingNote: response.data().endingNote,
          bottomLeft: response.data().bottomLeft,
          bottomRight: response.data().bottomRight,
          brideParents: response.data().brideParents,
          groomParents: response.data().groomParents,
          requestLine1: response.data().requestLine1.capitalize(),
          requestLine2: response.data().requestLine2,
          womanName: response.data().brideName.capitalize(),
          manName: response.data().groomName.capitalize(),
          day: dayName,
          baseSong: response.data().baseSong,
          registryLinks:response.data().registryLinks,
          venue: response.data().venueName,
          venueForMaps: forMap,
          venueAddress: response.data().venueAddress,
          cityState: response.data().cityState,
          phone: response.data().phone,
          isEnvelopeOpen: false,
          brideInitial:response.data().brideName.charAt(0).capitalize(),
          groomInitial :  response.data().groomName.charAt(0).capitalize(),
          allowRsvp : response.data().rsvp,
          link: `https://www.google.com/maps/search/?api=1&query=${forMap}`,
          isActive: response.data().isActive,
        }
      }
      else
      {
        there.reqObject =
        {
          eventDate : response.data().eventDate.toString(),
          envelopeColor: response.data().envelopeColor,
          registryLinks:response.data().registryLinks,
          brideInitial:response.data().brideName.charAt(0).capitalize(),
          groomInitial :  response.data().groomName.charAt(0).capitalize(),
          womanName: response.data().brideName.capitalize(),
          manName: response.data().groomName.capitalize(),
          customDesign:response.data().customDesign,
          baseSong: response.data().baseSong,
          venue: response.data().venueName,
          allowRsvp : response.data().rsvp,
          venueForMaps: forMap,
          venueAddress: response.data().venueAddress,
          isEnvelopeOpen: false,
          link: `https://www.google.com/maps/search/?api=1&query=${forMap}`,
          isActive: response.data().isActive,
        }

      }

      window.document.title = there.reqObject.womanName + " And " + there.reqObject.manName + "'s Wedding"

        console.log(response.data().isActive)
        there.setState({
          changed: true,
          template: there.template,
          isActive:there.isActive
        })
      }
      else{
        console.log('No Such Document')
      }


  }

  closeCard(prop) {
    prop.reqObject.isEnvelopeOpen = false;
    window.closeCard();
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.pause();
    audioEl.currentTime = 0;
  }
  openCard(prop) {
    prop.reqObject.isEnvelopeOpen = true;
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play();
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        audioEl.pause();
        //do whatever you want
        console.log("Hidden");
      }
      else {
        if (prop.reqObject.isEnvelopeOpen)
          audioEl.play();
        //do whatever you want
        console.log("SHOWN");
      }
    });

    window.openCard();
  }
  GetReqObject()
  {
    return this.reqObject;
  }
  render() {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (
      this.reqObject.changed===false ? 
      <div className="sweet-loading" style={style}>
      <RingLoader color={'rgb(209, 238, 248)'} loading={true} css={override} size={250} />
      </div> :
        this.reqObject.isActive? 
      <div>
        <div className="envContainer">
          <div className="close" onClick={() => this.closeCard(this)}>x</div>
          <div className="content">
            <div id="envelope" className="envelope" onClick={() =>this.openCard(this)}>
              <div className="my-circle" title="KM">{this.reqObject.brideInitial + '|' + this.reqObject.groomInitial}</div>
            </div>
            <div id="letter" className="letter">
              <div className="body" >
                <div id='message'>
                  <div id="letters" className="EnvelopeDiv">
                    {this.template === 'classic' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid black",
                          // height: "100vh", 
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Classic reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'wreath' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #d9af43",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Wreath reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'FloralFrame' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #d9af43",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <FloralFrame reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'marsalaFloral' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #22040F",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <MarsalaFloral reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'silverGlitter' ?
                      <div id='fullInvite'
                        style={{
                          border: "2px solid #B9B9B9",
                          height: "100%",
                          backgroundImage: `url(${silverBackground})`
                        }}>
                        <SilverGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'sakura' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #564861",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <Sakura reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'custom' ?
                      <div id='fullInvite' style={{

                        height: "100vh", background: 'transparent'
                      }}>
                        <CustomInvitation reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldDots' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #695746",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldDots reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldGlitter' ?
                      <div id='fullInvite' style={{
                        border: "2px solid #F0B9AD",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="powered">
        <a className="pricing" role="button" href="https://GeekVitations.NeedForGeeks.com" target="_blank" rel="noopener noreferrer" style={{ padding: "7px", borderColor: "#1DAEE0", color: "#173962", paddingLeft:"5px" }}>
            <img src={require('../assets/img/Logos/nav_icon.png')} width="40" height="30" className="d-inline-block align-top" alt="" style={{ paddingTop: "5px", paddingRight: "7px"}}/>
            Powered By: <b>NeedForGeeks</b>
          </a>
        </h4>
        <EnvelopeLinks reqObject={this.reqObject} />
      </div>
       :
       <div>
         <h3 style = {{"color":"red"}}>Your Invitation is not Active. This could be due to a invalid payment. Please contact NeedForGeeks to solve this issue;</h3>
       </div>
    )
  };
}