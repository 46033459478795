import React from "react";
import { Typography } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';

function RSVPTable() { 
  const [totalRows,setTotalRows] = React.useState(0);
  const [rows,setRowData] = React.useState([]);
  
 React.useEffect(() => {
    fetchData();
  }, []);

 const fetchData = () => {
  
  var urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('documentId');
      const axios = require('axios')

      axios.post(process.env.REACT_APP_API_URL + '/getRSVP',{
        "documentId": myParam,
        "collectionPath": "RSVP"
      }).then(function(response){
        var row = [];
       
        var count = 0;
        response.data.forEach(function(item) {
          
          item.forEach(i =>
         {
          
           if(i.firstName !=null)
           {
            
          row = {id:count,FirstName:i.firstName,LastName:i.lastName, Adults:i.adults, Children:i.children,Email:i.email,Message:i.message,Attending:i.attending};
          setRowData(oldArray => [...oldArray, row]);
          count = count+1;
            
        }
        });
       
        }); // logs 1, 3
        setTotalRows(count);
       
      });
    
};

    const columns =[
      {
        field: 'id',
        hide: true,
    },
      { field: 'FirstName', headerName: 'First name', width: 200 },
      { field: 'LastName', headerName: 'Last name', width: 200 },
      {
        field: 'Adults',
        headerName: 'Adults',
        type: 'number',
        width: 200,
      },
      {
        field: 'Children',
        headerName: 'Children',
        type: 'number',
        width: 200,
      },
       {
        field: 'Email',
        headerName: 'Email',
       
        width: 200,
      },
      {
        field: 'Message',
        headerName: 'Message',
        width: 200,
      },
      {
        field: 'Attending',
        headerName: 'Attending',
        width: 200,
      },
    ];
    
    return (
      <div id="rsvp-table-page" className="bg-transparent " width="100%">
        <div className="container container-fluid text-center">
        <div style={{ height: 600, width: '100%' }}>
          <Typography variant="h2" display="block" align="center"
            style={{
              paddingTop: "0",
              margin: "0rem",
              fontSize: "2.5rem",
              marginBottom: "15px"
            }}
          >
            RSVP Details
          </Typography>
          <Typography variant="h2" display="block" align="right"
            style={{
              paddingTop: "0",
              margin: "0rem",
              fontSize: "2.5rem",
              marginBottom: "15px"
            }}
          >
            Total: {totalRows}
          </Typography>
        
          <div style={{height:400,width:"100%"}} >
          <DataGrid style={{border:"none"}} rows={rows} columns={columns} pageSize={10} />
          </div>
          <br/>
        </div>
      </div>
    </div> 
  );
}

export default withRouter(RSVPTable);