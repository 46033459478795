import React from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import "../App";

export default function ComingSoonPage() {
  window.document.title = "NeedForGeeks | Coming Soon...";
  return (
    <div>
      <div
        id="comingsoon"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-1">Under Construction...</h1>
          <Typist className = "lead" cursor={{ hideWhenDone: true }} avgTypingDelay={30}>
            <span className="lead">Welcome to our site! We are currently working on this site every minute of an hour.</span>
          </Typist>
          
          <div>
            {/* <form autoCorrect="off" onSubmit={e => handleSubmit(e)} id="subscribe-form"
              style={{backgroundColor: "white", flexDirection: "column", padding: "15px", borderRadius: "10px", maxWidth:"650px", display:"inline-block", width:"100%",}}
            >
              <div className="form-row">
                <div className="form-group col-md-8">
                  <TextField required label="Email Address" type="email" id="subscribe" margin="normal" variant="outlined" placeholder="Email Address" style={{height: "56px", paddingTop: 0, width: "100%", }}/>
                </div>
                <div className="form-group col-md-2" style={{paddingTop: "13px",}}>
                  <Button variant="contained" color="primary" size="large" type="submit" className={classes.button} endIcon={<SubscriptionsRounded />}> Subscribe!</Button> 
                </div>
              </div>             
            </form> */}
          </div>
          <div className="container container-fluid text-center p-5" style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <Link className="btn btn-outline-light" role="button" to="/">Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
