import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import HomeTab from "./TextTab";
import EnvelopeTab from './EnvelopeTab';
import LinksTab from './LinksTab';
import DesignTab from './DesignTab'; 

function TabPanel(props) {
  const {children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export default function CustomizeTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <div id="CustomizeTabs" className="jumbotron jumbotron-fluid min-vh-auto m-0 " style={{background: "#d1eef8"}}>
      <div className="container container-fluid">
        <h2 className="display-4-overview mb-3 text-center">{'Fully Customizable Design'}</h2><br/>
          <AppBar style={{ background: '#173962', borderRadius:"8px"}} position="static" color="primary">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#D97D54",
                  height: "150px",
                  zIndex: 0,
                }
              }}
              scrollButtons="off"
              aria-label="scrollable prevent tabs example"
            >
              <Tab style={{zIndex: 1}} label="Text" aria-label="phone" {...a11yProps(0)} />
              <Tab style={{zIndex: 1}} label="Envelope" aria-label="person" {...a11yProps(1)} />
              <Tab style={{zIndex: 1}} label="Links" aria-label="help" {...a11yProps(2)} />
              <Tab style={{zIndex: 1}} label="Your Own Design" aria-label="Design" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
             <HomeTab/> 
          </TabPanel>
          <TabPanel value={value} index={1}>
              <EnvelopeTab/>
          </TabPanel>
          <TabPanel value={value} index={2}>
              <LinksTab/> 
          </TabPanel>
          <TabPanel value={value} index={3}>
              <DesignTab/> 
          </TabPanel>
      </div>
    </div>
  );
}
