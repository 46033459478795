import React from "react";
import { Button, makeStyles, Radio, RadioGroup, FormControlLabel, FormLabel, Grid, TextField, Typography, MenuItem, InputLabel, Select } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function RSVPForm() {
  const classes = useStyles();

  const [attending, setAttending] = React.useState('yes');
  const [adults, setAdults] = React.useState('0');
  const [children, setChildren] = React.useState('0');


  const handleAttendChange = (event) => {
    setAttending(event.target.value);
  };

  const handleAdultsChange = (event) => {
    setAdults(event.target.value);
  };

  const handleChildrenChange = (event) => {
    setChildren(event.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const firstName = document.getElementById("firstname").value;
    const lastName = document.getElementById("lastname").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    var urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('documentId');
    axios.post(process.env.REACT_APP_API_URL + '/setRSVP', {
      documentId: myParam,
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
      adults: adults,
      children: children,
      attending: attending
    })
      .then(function (response) {
      
      });
      document.getElementById("rsvp-form").reset();
      setChildren('0');
      setAdults('0');
      setAttending('yes');
      window.alert("Your RSVP request was submitted and the host was notified. Thank you!")
  };


  return (
    <div
      id="rsvpForm"
      className="jumbotron jumbotron-fluid min-vh-100 m-0"
      style={{ background: "white" }}
      width="100%"
    >
      <div className="container container-fluid text-center">
        <Typography variant="h2" display="block" align="center"
          style={{
            paddingTop: "4rem",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Will You Attend Our Event?
        </Typography>
        <Typography variant="h2" display="block" align="center"
          style={{
            paddingTop: "2rem",
            paddingBottom: "1.5rem",
            margin: "0rem",
            fontSize: "1.5rem",
          }}
        >
          Please let us know whether or not you can make it to our event.
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={e => handleSubmit(e)}
          id="rsvp-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "650px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", flexWrap: "wrap", paddingTop: "5px" }}>
            <RadioGroup name="attending" value={attending} >
              <Grid container>
                <Grid item>
                  <FormLabel component="legend">Can you Attend?</FormLabel>
                  <FormControlLabel value="yes" control={<Radio />} label="Can Attend" onClick={handleAttendChange} />
                  <FormControlLabel value="no" control={<Radio />} label="Cannot Attend" onClick={handleAttendChange} />
                </Grid>
              </Grid>
            </RadioGroup>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>First Name: <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" variant="outlined" id="firstname" style={{ width: "100%" }} />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Last Name: <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="text" id="lastname" variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Email Address: <span style={{ color: "red" }}>*</span></InputLabel>
              <TextField required type="email" id="email" variant="outlined" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Number of Adults: <span style={{ color: "red" }}>*</span></InputLabel>
              <Select
                value={adults}
                onChange={handleAdultsChange}
                required type="text" variant="outlined" id="adults" style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Adults</em>
                </MenuItem>
                <MenuItem value={"0"}>No Adults</MenuItem>
                <MenuItem value={"1"}>One</MenuItem>
                <MenuItem value={"2"}>Two</MenuItem>
                <MenuItem value={"3"}>Three</MenuItem>
                <MenuItem value={"4"}>Four</MenuItem>
                <MenuItem value={"5"}>Five</MenuItem>
                <MenuItem value={"6"}>Six</MenuItem>
                <MenuItem value={"7"}>Seven</MenuItem>
                <MenuItem value={"More"}>More</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Number of Children: <span style={{ color: "red" }}>*</span></InputLabel>
              <Select
                value={children}
                onChange={handleChildrenChange}
                required type="text" variant="outlined" id="children" style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Children</em>
                </MenuItem>
                <MenuItem value={"0"}>No Children</MenuItem>
                <MenuItem value={"1"}>One</MenuItem>
                <MenuItem value={"2"}>Two</MenuItem>
                <MenuItem value={"3"}>Three</MenuItem>
                <MenuItem value={"4"}>Four</MenuItem>
                <MenuItem value={"5"}>Five</MenuItem>
                <MenuItem value={"6"}>Six</MenuItem>
                <MenuItem value={"7"}>Seven</MenuItem>
                <MenuItem value={"More"}>More</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-group">
            <InputLabel style={{ textAlign: "left" }}>Message to Hosts:</InputLabel>
            <TextField type="text-area" id="message" multiline rows={4} variant="outlined" placeholder="Message" style={{ width: "100%" }} />
          </div>
          <div className="form-group">
            <Button style={{ borderColor: "#1DAEE0", color: "#1DAEE0" }} variant="outlined" color="primary" size="large" type="submit" className={classes.button} endIcon={<Send />}>RSVP</Button>
          </div>
        </form>


      </div>
    </div>
  );
}