import React, {useState} from "react";
import {Button, TextField, InputLabel, IconButton} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";

export default function RegistryLinks(props) {
  const [inputList, setInputList] = useState([{ registryLabel: "", registryURL: "" }]);
 
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    props.handleRegistryLinkChange(list)
  };
 
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
 
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { registryLabel: "", registryURL: "" }]);
  };
 
  return (
    <div>
      <div className="form-row">
        <div className="form-group col-md">
          <h2 display="block" align="left"
            style={{
              paddingTop: "20px",
              margin: "0rem",
              paddingBottom: "20px",
              fontSize: "1.5rem",
            }}
          >
            Registry Links
          </h2>
        </div>
        <div className="form-group col-md" style={{ textAlign: "right"}}>
          <Button variant="contained" style={{ backgroundColor: "#1DAEE0", color: "white", marginTop:"15px"}} onClick={handleAddClick} startIcon={<Add />}>Add</Button>
        </div>
      </div>
      {inputList.map((x, i) => {
        return (
          <div className="box">
            <div className="form-row" style={{ textAlign: "center" }}>
              <div className="form-group col-md-3">
                <InputLabel style={{ textAlign: "left" }}>Label</InputLabel>
                <TextField name="registryLabel" type="text" value={x.registryLabel} onChange={e => handleInputChange(e, i)} id="registryLabel" variant="outlined" style={{ width: "100%" }} placeholder="Amazon"/>
              </div>
              <div className="form-group col-md">
                <InputLabel style={{ textAlign: "left" }}>URL</InputLabel>
                <TextField name="registryURL" type="url" value={x.registryURL} onChange={e => handleInputChange(e, i)} id="registryURL" variant="outlined" style={{ width: "100%" }} placeholder="https://www.amazon.com" />
              </div>
              <div className="form-group col-md-1" style={{maxWidth: "50px"}}>
                {inputList.length !== 1 &&
                  <IconButton style={{ marginTop: "15px" }} color="secondary" aria-label="delete" onClick={() => handleRemoveClick(i)}>
                    <Delete />
                  </IconButton>}
              </div>
            </div>
          </div>
        );
      })}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </div>
  );
}