import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import Typist from "react-typist";
import { Player, BigPlayButton, ControlBar } from 'video-react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Configs from "../../editable-stuff/configurations.json";
import "../../App";
import { scroller } from 'react-scroll';
import "./CSS/video-react.css";

const scrollFunc=(e)=>{
    scroller.scrollTo(e, {
      duration: 1400,
      delay: 0,
      offset: 25,
      smooth: 'easeInOutQuart'
    })
}

const MainHeader = () => {
  const [devInfo] = useState(Configs.devIntro);
  const [devDesc] = useState(Configs.devDesc);
  window.document.title = "Personalized e-Invitations";
  return (
    <div>
      <div
        id="mainHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light  d-flex align-content-center align-items-center "
        style={{marginBottom:0,height:"100vh"}}
     >
        <div className="container container-fluid text-center margin-main">
          <div className="row">
            <div className="col-md">
              <h1 className="display-3-main">{devInfo}</h1>
              <p className="display-4-main mb-4 ">Save Time, Save Trees, Save Money <br></br> Email Invitations + RSVP Tracking</p>
              <Typist className="lead-main" avgTypingDelay={30}> {devDesc}</Typist>
            </div>
            <div className="col-md"  >
              <Player
              style={{borderRadius:"30px"}}
                autoPlay
                playsInline
                fluid="false"
                src={'https://Moddevnyinvitations.b-cdn.net/sample.mp4'}
                // poster={require('../Invitations/assets/img/Other/sakuraBackground.jpg')}
              >
                <BigPlayButton position="center" />
                <ControlBar disableCompletely="true"/>
              </Player>
            </div>
          </div>
          <br/><br/>
          <IconButton  onClick={()=>scrollFunc("overview")} style={{backgroundColor:"#d1eef8", position:"absolute",bottom: "10px",left:"50%", marginLeft:"-30px"}}>
            <ExpandMoreOutlinedIcon style={{}} fontSize="large" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
