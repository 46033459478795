import React from "react";
import Classic from "./BabyTemplates/Classic";
import Wreath from "./BabyTemplates/Wreath";
import Sakura from "./BabyTemplates/Sakura";
import GoldDots from "./BabyTemplates/GoldDots";
import GoldGlitter from "./BabyTemplates/GoldGlitter";
import MarsalaFloral from "./BabyTemplates/MarsalaFloral";
import SilverGlitter from "./BabyTemplates/SilverGlitter";
import "./BabyTemplates/CSS/Medias.css";
import '../assets/css/envelopeCss.scss';
import texturedBackground from "../assets/img/Other/texturedBackground.png";
import silverBackground from "../assets/img/SilverGlitter/background.png";
import CustomInvitation from "./BabyTemplates/CustomInvitation";
import EnvelopeLinks from "../assets/resources/EnvelopeLinks";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";
import { doc, getDoc } from "firebase/firestore";

import {db} from '../../../firebase';

var converter = require('number-to-words');

const axios = require('axios');
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class Envelope extends React.Component {
  constructor() {
    super();
    this.reqObject =
    {
      eventDate: '',
      template: '',
      requestLine1: '',
      requestLine2: '',
      requestLine3: '',
      womanName: '',
      day: '',
      date: '',
      year: '',
      time: '',
      venue: '',
      venueForMaps: '',
      venueAddress: '',
      cityState: '',
      phone: '',
      link: '',
      footerMessage1: '',
      footerMessage2: '',
      endingNote: '',
      changed: false,
      brideInitial: '',
      customDesign: '',
      registryLinks: '',
      isEnvelopeOpen: false,
     
    }
  }
  getHours(date) {
    return date.getHours() % 12 || 12;
  }

  async componentWillMount() {

    var urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('documentId');
    var there = this;
    var ss = "Invitations";
    if(window.location.href.includes('localhost'))
    {
      ss = 'Test-Invitations';
    }
    const docRef = doc(db, ss, myParam);
    const response =  await getDoc(docRef);
    console.log(response.exists)
        if (response.exists) {
            console.log("Document data:", response.data());

            there.template = response.data().template
            if (there.template === "sakura") {
              there.isSakura = true;
            }
    
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d = new Date();
            
              d = new Date(response.data().eventDate.toString());
            
    
    
            var dayName = days[d.getDay()];
            var date = converter.toOrdinal(d.getUTCDate());
            // eslint-disable-next-line no-extend-native
            String.prototype.capitalize = function () {
              return this.charAt(0).toUpperCase() + this.slice(1);
            }
    
            var hours = d.getHours();
            var ampm = hours >= 12 ? 'pm' : 'am';
            var timeOfDay = ampm.includes("am") ? "morning" : "evening";
            var forMap = response.data().venueName.split(' ').join('+');
            var envelopeColor  = response.data().envelopeColor ==null?"black":response.data().envelopeColor;
            there.time = converter.toWords(there.getHours(d)) + " " + converter.toWords(d.getMinutes()) + " in the " + timeOfDay;
            var newTime = there.time.replace("zero", "o'clock");
            document.documentElement.style.setProperty("--base", envelopeColor);
            document.documentElement.style.setProperty("--base-dark",envelopeColor);
            document.documentElement.style.setProperty("--base-light",envelopeColor);
            document.documentElement.style.setProperty("--letter", envelopeColor);
            if (response.data().template !== 'custom') {
              there.reqObject =
              {
                eventDate: response.data().eventDate.toString(),
                registryLinks: response.data().registryLinks,
                template: response.data().template,
                date: "the " + date + " of " + d.toLocaleString('default', { month: 'long' }),
                year: converter.toWords(d.getFullYear()).split(',').join(' and ').capitalize(),
                time: newTime,
                footerMessage1: response.data().footerMessage1,
                footerMessage2: response.data().footerMessage2,
                customDesign: response.data().customDesign,
                endingNote: response.data().endingNote,
                requestLine1: response.data().requestLine1.capitalize(),
                requestLine2: response.data().requestLine2,
                requestLine3: response.data().requestLine3,
                womanName: response.data().brideName.capitalize(),
                day: dayName,
                baseSong: response.data().baseSong,
                venue: response.data().venueName,
                venueForMaps: forMap,
                venueAddress: response.data().venueAddress,
                cityState: response.data().cityState,
                phone: response.data().phone,
                isEnvelopeOpen: false,
                brideInitial: response.data().brideName.charAt(0).capitalize(),
                link: `https://www.google.com/maps/search/?api=1&query=${forMap}`
              }
            }
            else {
              there.reqObject =
              {
                eventDate: response.data().eventDate.toString(),
                registryLinks: response.data().registryLinks,
                brideInitial: response.data().brideName.charAt(0).capitalize(),
                womanName: response.data().brideName.capitalize(),
                customDesign: response.data().customDesign,
                baseSong: response.data().baseSong,
                venue: response.data().venueName,
                venueForMaps: forMap,
                venueAddress: response.data().venueAddress,
                isEnvelopeOpen: false,
                link: `https://www.google.com/maps/search/?api=1&query=${forMap}`
              }
    
            }
            window.document.title = there.reqObject.womanName

            console.log(there.template)
            there.setState({
              changed: true,
              template: there.template
            })
    

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        
        
          }
  
    
    
    }

  closeCard(prop) {
    prop.reqObject.isEnvelopeOpen = false;
    window.closeCard();
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.pause();
    audioEl.currentTime = 0;
  }
  openCard(prop) {
    prop.reqObject.isEnvelopeOpen = true;
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play();
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        audioEl.pause();
        //do whatever you want
        console.log("Hidden");
      }
      else {
        if (prop.reqObject.isEnvelopeOpen)
          audioEl.play();
        //do whatever you want
        console.log("SHOWN");
      }
    });
    window.openCard();
  }
  render() {
    var stickerEnvelopeClass = 'my-circle-woman';
    var stickerText ='';
    if(this.reqObject.brideName != null && this.reqObject.groomName!=null)
    {
      stickerText = this.props.brideInitial + '|' + this.state.groomInitial;
    }
    else if(this.reqObject.brideName != null && this.reqObject.groomName==null)
    {
      if(this.reqObject.brideName.capitalize().includes('BOY')|| this.reqObject.brideName.capitalize().includes('GIRL'))
    {
      stickerText = this.reqObject.brideName.charAt(0).capitalize();
    }
    else
    {
    stickerText = this.reqObject.brideInitial;
    }
  }


    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
     return (
      this.reqObject.changed === false ? 
      <div className="sweet-loading" style={style}>
      <RingLoader color={'rgb(209, 238, 248)'} loading={true} css={override} size={250} />
      </div> :
      <div>
        <EnvelopeLinks reqObject={this.reqObject} />
        <div className="envContainer" >
          <div className="close" onClick={() => this.closeCard(this)}>x</div>
          <div className="content">
            <div id="envelope" className="envelope" onClick={() => this.openCard(this)}>
              <div id="stickerEnvelope" className ={stickerEnvelopeClass} title="KM">
              <img alt="..." src={require(`../assets/img/Baby/${this.reqObject.gender === 'girl' ? 'sticker2.png' : 'sticker1.png'}`)} className ={"stickernew"}/>
              </div>
            </div>
            <div id="letter" className="letter">
              <div className="body" >
                <div id='message'>
                  <div id="letters" className="EnvelopeDiv">
                    {this.template === 'classic' ?
                      <div id='fullInvite' class="fullInvite"
                        style={{
                          border: "2px solid black",
                          // height: "100vh", 
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Classic reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'wreath' ?
                      <div id='fullInvite' class="fullInvite"
                        style={{
                          border: "2px solid #d9af43",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <Wreath reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'marsalaFloral' ?
                      <div id='fullInvite' class="fullInvite"
                        style={{
                          border: "2px solid #22040F",
                          height: "100%",
                          backgroundImage: `url(${texturedBackground})`
                        }}>
                        <MarsalaFloral reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'silverGlitter' ?
                    <div id='fullInvite'
                      style={{
                        border: "2px solid #B9B9B9",
                        height: "100%",
                        backgroundImage: `url(${silverBackground})`
                      }}>
                      <SilverGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                    </div>
                    :
                    this.template === 'sakura' ?
                      <div id='fullInvite' class="fullInvite" style={{
                        border: "2px solid #564861",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <Sakura reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'custom' ?
                      <div id='fullInvite' class="fullInvite" style={{

                        height: "100vh", background: 'transparent'
                      }}>
                        <CustomInvitation reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={true} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldDots' ?
                      <div id='fullInvite' class="fullInvite" style={{
                        border: "2px solid #695746",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldDots reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    :
                    this.template === 'goldGlitter' ?
                      <div id='fullInvite' class="fullInvite" style={{
                        border: "2px solid #F0B9AD",
                        // height: "100vh", 
                        backgroundImage: `url(${texturedBackground})`
                      }}>
                        <GoldGlitter reqObject={this.reqObject} handleReqObject={this.handleReqObject} isSakura={false} isSideBySide={false} />
                      </div>
                    : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="powered">
          <a className="pricing" role="button" href="https://GeekVitations.NeedForGeeks.com" target="_blank" rel="noopener noreferrer" style={{ padding: "7px", borderColor: "#1DAEE0", color: "#173962", paddingLeft:"5px" }}>
            <img src={require('../assets/img/Logos/nav_icon.png')} width="40" height="30" className="d-inline-block align-top" alt="" style={{ paddingTop: "5px", paddingRight: "7px"}}/>
            Powered By: <b>NeedForGeeks</b>
          </a>
        </h4>
      </div>
    )
  };
}