import React from "react";
import { Player, BigPlayButton, ControlBar } from 'video-react';
//import "../../node_modules/video-react/dist/video-react.css";

export default class InviteSample extends React.Component {
  componentWillMount(){
   
  }
  render() 
  {
  return (
   
        <div style={{width:"600px", height:"100vh",borderRadius:"30px"}} className="container container-fluid text-center">
          <Player
            autoPlay
            playsInline
            fluid="false"
            src={'https://Moddevnyinvitations.b-cdn.net/sample.mp4'}
            // poster={require('../Invitations/assets/img/Other/sakuraBackground.jpg')}
          >
            <BigPlayButton position="center" />
            <ControlBar disableCompletely="true"/>
          </Player>
      
    </div>
  );
  }
};

