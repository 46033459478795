import React from "react";
import {Link, Phonelink } from "@material-ui/icons";


const LinksTab = () => {
  return (
    <div className='row p-3'>
      <ul className="nav nav-tabs tabs-padding" id="linksTab" role="tablist">
        <div className="row">
          <div className="col-md">
            <div className="row" style={{justifyContent:"center"}}>
              <div>{<Link />}</div>
            </div>
            <div className="row">
              <p>Add links to your registry for recipients to seamlessly send registry gifts.</p>
            </div>
          </div>
          <div className="col-md">
            <div className="row" style={{justifyContent:"center"}}>
              <div>{<Phonelink />}</div>
            </div>
            <div className="row">
              <p>Links for RSVP and Venue allow recipients to easily navigate and let you know if they can make it!</p>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default LinksTab;
