import React from "react";
// import { Button } from "@material-ui/core";


const Difference = () => {
 
  return (
    <div id="difference" className="jumbotron jumbotron-fluid min-vh-auto m-0" style={{paddingTop:"80px", background:"white"}}>
      <div className="container container-fluid p-5">
        <div className="row" style={{marginBottom:"-50px"}}>
          <div className={`col-lg`}>
            <h1 className="display-4-overview mb-3 text-center">{'Making A Difference'}</h1>
            <p className="lead text-center">{'By creating a positive online experience, we hope more hosts will consider sending invitations electronically for formal events – to help save trees, time, and money. To express our commitment towards this goal, we donate a percentage of every sale to a non-profit organization that maintains forests. Together we can "invite" a greener future.'}
            <br/><br/>
            {/* <Button style={{margin: "3px", borderColor:"#1DAEE0", color:"#1DAEE0"}} variant="outlined" size="large" type="submit"  >Learn More About XXXXX</Button> */}
            </p>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default Difference;