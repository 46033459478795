import React from "react";
import {ContactMailOutlined, DraftsOutlined } from "@material-ui/icons";


const EnvelopeTab = () => {
  return (
    <div className='row p-3'>
        <ul className="nav nav-tabs tabs-padding" id="envelopeTab" role="tablist">
          <div className="row">
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<ContactMailOutlined />}</div>
              </div>
              <div className="row">
                <p>All envelopes are personalized with the bride and groom's initials on the front.</p>
              </div>
            </div>
            <div className="col-md">
              <div className="row" style={{justifyContent:"center"}}>
                <div>{<DraftsOutlined />}</div>
              </div>
              <div className="row">
                <p>Our envelopes match the envelope text, color, lining, and style to your card.</p>
              </div>
            </div>
          </div>
        </ul>
    </div>
  );
};

export default EnvelopeTab;
