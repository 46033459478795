import React from "react";
import AOS from 'aos';
import {Link} from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";


AOS.init();
const HowToPay = () => {
  return (
    <div>
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="how-to-pay" style={{ overflowX: "hidden", }}>
          <div className="container">

            <div className="card-deck" data-aos="fade-right" style={{ display: "flex", justifyContent: "center", paddingLeft: "20px", paddingRight: "20px" }}>
              <div className="row">
                <div className="card">
                  <img style={{ height: "300px" }} src={require('./Logos/cheque.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Checks</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">If you prefer to pay by check, contact us and we will make the arrangements</li>
                    </ul>
                  </div>
                </div>

                <div className="card">
                  <img style={{ height: "300px" }} src={require('./Logos/cash.png')} alt="" />
                  <div className="card-body ">
                    <h5 className="card-title">Cash</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">Cash is always preferred if possible. Contact Us to set up a Drop off/ Pick up time.</li>
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <img style={{ height: "300px" }} src={require('./Logos/credit-card.png')} alt="" />
                  <div className="card-body">
                    <h5 className="card-title">Credit Card</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">NeedForGeeks accepts all major credit cards! A 3% processing fee will apply for this method</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-deck" data-aos="fade-right" style={{ display: "flex", justifyContent: "center", paddingLeft: "20px", paddingRight: "20px" }}>
              <div className="row">
               
                <div className="card" >
                <Link to={{ pathname: "http://www.paypal.com"}} target="_blank">
                  <img style={{ height: "300px" }} src={require('./Logos/paypal.png')} alt="" />
                  <div style={{color:"black"}} className="card-body">
                    <h5 className="card-title">PayPal</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">Use <b>payments@needforgeeks.com</b> with PayPal and choose the "Friend's and Family" option to avoid unnecessary fees.</li>
                    </ul>
                  </div>
                  </Link>
                </div>
               
              
                <div className="card">
                <Link to={{ pathname: "http://www.venmo.com"}} target="_blank">
                  <img style={{ height: "300px" }} src={require('./Logos/venmo.png')} alt="" />
                  <div style={{color:"black"}} className="card-body">
                    <h5 className="card-title">Venmo</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">The easiest way to pay and avoid all fees. Our VENMO username is <b>NeedForGeeks</b></li>
                    </ul>
                  </div>
                  </Link>
                </div>
             
                
                <div className="card">
                <Link to={{ pathname: "http://www.zellepay.com"}} target="_blank">
                  <img style={{ height: "300px" }} src={require('./Logos/Zelle.png')} alt="" />
                  <div style={{color:"black"}} className="card-body">
                    <h5 className="card-title">Zelle QuickPay</h5>
                    <ul className="list-group list-group-flush" >
                      <li className="list-group-item">We accept Zelle Quickpay transfers through Zelle sent to: <b>payments@needforgeeks.com</b> </li>
                    </ul>
                  </div>
                  </Link>
                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
export default HowToPay;
